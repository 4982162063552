<template>
    <BubbleContent
        class="exams-edit-import"
        :loadingInfo="loadingInfo"
        :title="`${$t('action.shaping.importAnswer')}`"
        :buttons="importButtons"
        @submit="importFiles">
        <template #form>
            <InputFile :label="`${$tc('models.answer')}`" v-model="importFile" /> 
            <InputFile :label="`${$t('generic.attachment')}`" v-model="importFileAttachments" />
        </template>
    </BubbleContent>
</template>
<script>
import BubbleContent from '../BubbleContent/BubbleContent.vue';
import InputFile from '../Inputs/InputFile.vue';
import uuid4 from 'uuid4';

export default {
    components: {
        BubbleContent,
        InputFile,
    },
    props: {
        id: {
            required: true
        }
    },
    data() {
        return {
            uid: null,
            date: new Date().toLocaleString(),
            importFile: [],
            importFileAttachments: [],
        };
    },
    methods: {
        importFiles() {
            this.$store.dispatch('exams/import', {
                id: this.id,
                file: this.importFile ? this.importFile[0] : null,
                attachment: this.importFileAttachments ? this.importFileAttachments[0] : null,
                uid: this.uid
            })
            this.importFile = []
            this.importFileAttachments = []
        },
    },
    computed: {
        canSubmit() {
            return this.importFile[0] != null || this.importFileAttachments[0] != null
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        importButtons() {
            if (this.exam.task_pinged_at != null) {
                return []
            }
            return [{ text: `${this.$t('action.decisive.import')}`, type: 'submit', disabled: !this.canSubmit }]
        },
        loadingInfo() {
            return this.messages
        },
        messages() {
            return this.$store.getters['loadingMessages/messages'](this.uid)
        },
    },
    mounted() {
        this.uid = uuid4();
        if (this.exam == null) {
            this.$store.dispatch('exams/getId', this.id)
        }

    },

}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';
</style>
