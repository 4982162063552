<template>
    <ul class="options" :style="style" v-if="show && !closed">
        <li v-for="option, index in options"
            :key="index"
            :class="{disabled: option.disabled}"
            @click.prevent.stop="run(option)">
            {{ option.text }}
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        options: {required: true},
        show: {default: false},
    },
    components: {
    },
    data() {
        return {
            closed: false,
        }
    },
    methods: {
        run(option) {
            if( option.disabled ) {
                return
            }
            this.closed = true
            if( option.action == null ) {
                return
            }
            option.action()
        }
    },
    computed: {
        x() {
            if( this.show == null ) {
                return 0;
            }
            return this.show.x
        },
        y() {
            if( this.show == null ) {
                return 0;
            }
            return this.show.y
        },
        style() {
            return {
                "--left": `calc(${this.x}px - 10px)`,
                "--top": `calc(${this.y}px - 10px)`,
            }
        },
    },
    watch: {
        show() {
            this.closed = false
        },
    },
    mounted() {
    },
}

</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.options {
    position: fixed;
    left: -.75em;
    top: .75em;
    left: var(--left);
    top: var(--top);
    background: $white;
    box-shadow: 0px 2px 6px rgba($black, .4);
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 9000;
    font-size: 1rem;
    overflow: hidden;
    border-radius: 4px;
    text-align: left;

    li {
        padding: 1em 2em;
        cursor: pointer;
        user-select: none;

        &.disabled {
            opacity: .5;
            cursor: not-allowed;
        }

        &:not(.disabled) {

            &:hover {
                background-color: rgba($primary-color, .2);
            }
    }
    }
}


</style>
