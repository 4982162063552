<template>
    <transition name="fade">
        <div class="fixed right-0 bottom-0" v-show="scY > 20" @click="toTop">
            <IconComponent icon="top-arrow" class="material-icons" />
        </div>
    </transition>
</template>
<script>
import IconComponent from '@/components/IconComponent.vue'

export default {
    components: {
        IconComponent,
    },
    data() {
        return {
            scTimer: 0,
            scY: 0,
        }
    },
    methods: {
        handleScroll() {
            if (this.scTimer) return
            this.scTimer = setTimeout(() => {
                this.scY = window.scrollY
                clearTimeout(this.scTimer)
                this.scTimer = 0
            }, 10)
        },
        toTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },

}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fixed{
    position: fixed;
    left: 93%;
    top: 96%;

    
    .material-icons {
        width: 30px;
        fill: $primary-color;
        cursor: pointer;
    }
}
</style>
