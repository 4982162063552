import theEcho from '@/echoInstance'
import axios from "@/axiosInstance"

const classifiers = {
    namespaced: true,
    state: () => ({
        all: [],
        channel: null,
        loadingExams: [],
        importing: {},
    }),
    getters: {
        class: (state) => (id) => {
            return state.all.filter(({exam_class_id}) => exam_class_id == id)
        },
        exam: (state) => (id) => {
            return state.all.filter(({exam_id}) => exam_id == id)
        },
        examFirst: (state) => (id) => {
            return state.all.find(({exam_id}) => exam_id == id)
        },
        getId: (state) => (id) => {
            return state.all.find( classifier => classifier.id == id)
        },
        loading: (state) => (id) => {
            return state.loadingExams.includes(id)
        },
        importing: state => id => {
            return state.importing[id] == null ? false : state.importing[id]
        },
    },
    mutations: {
        add(state, classifier) {
            let index = state.all.findIndex( ({id}) => id == classifier.id )
            if( index < 0 ) {
                state.all.push(classifier)
                return
            }
            state.all.splice(index,1,classifier)
        },
        addMany(state, classifiers) {
            for( let classifier of classifiers ) {
                let index = state.all.findIndex( ({id}) => id == classifier.id )
                if( index > -1 ) {
                    state.all.splice(index,1,classifier)
                } else {
                    state.all.push(classifier)
                }
            }
        },
        channel(state, channel) {
            state.channel = channel
        },
        clear(state) {
            state.all = []
            state.loadExams = []
        },
        importing(state, data) {
            state.importing[data.examId] = data.state
        },
        loadExam(state, examId) {
            state.loadingExams.push(examId)
        },
        loadedExam(state, examId) {
            state.loadingExams = state.loadingExams.filter( id => id != examId )
        },
        update(state, {classifierId, itemId, o, n}) {
            let index = state.all.findIndex( ({id}) => id == classifierId )
            if( index < 0 ) {
                return
            }
            let classifier = state.all[index]
            let classified = classifier.classified_items
            if( classifier == null ) {
                return
            }
            if( classified[itemId] == null ) {
                classified[itemId] = {}
            }
            if( classified[itemId][o] == null ) {
                classified[itemId][o] = 0
            }
            if( classified[itemId][n] == null ) {
                classified[itemId][n] = 0
            }
            classified[itemId][o] -= 1
            classified[itemId][n] += 1
            classifier.classified_items = classified
            state.all.splice(index,1,{...classifier})
        },
    },
    actions: {
        async import( {commit}, {examId, file} ) {
            commit('importing', {examId, state: true})
            let formData = new FormData()
            formData.append("file", file)
            await axios.post(
                `${process.env.VUE_APP_API_HOST}/exams/${examId}/classifiers`,
                formData,
                {headers: {
                    'Content-Type': 'multipart/form-data'
                }}
            )
            commit('importing', {examId, state: false})
        },
        async update( {commit}, {classifier, o, n}) {
            if( classifier == null ) {
                return
            }
            commit('update', {classifier, rem: o, add: n})
        },
        async upgrade( {commit}, classifierId ) {
            let res = await axios.post(
                `${process.env.VUE_APP_API_HOST}/exams/classifiers/${classifierId}`,
                {headers: {
                    'Content-Type': 'multipart/form-data'
                }}
            )
            commit('add', res.data.data)
        },
        async get( {commit}, id) {
            let res = await axios.get(`${process.env.VUE_APP_API_HOST}/users/${id}/classifiers`)
            commit('addMany', res.data.data)
        },
        async getAll( {state, commit}, {examId, page = null} ) {
            if( state.loadingExams.includes(examId) ) {
                return
            }
            commit('loadExam', examId)
            let res = null
            if( page == null ) {
                res = await axios.get(`${process.env.VUE_APP_API_HOST}/exams/${examId}/classifiers`)
            } else {
                res = await axios.get(`${process.env.VUE_APP_API_HOST}/exams/${examId}/classifiers`, {
                    params: {
                        page
                    }
                })
            }
            commit('addMany', res.data.data)
            commit('loadedExam', examId)
        },
        async getId( {commit}, id ) {
            const res = await axios.get(`${process.env.VUE_APP_API_HOST}/exams/classifiers/${id}`)
            commit('add', res.data.data)
        },
        async getClassClassifiers( {commit}, classId ) {
            try {
                let res = await axios.get(`${process.env.VUE_APP_API_HOST}/exams/classes/${classId}/classifiers`)
                commit('addMany', res.data.data)
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        listen({state, commit}, classifier_id) {
            if( state.channel != null ) {
                console.log("STOP LISTENING CLASS");
                state.channel.stopListening('Classifiers\\Updated')
                commit('channel', null)
            }
            let channel = theEcho.echo.private(`classifiers.${classifier_id}`)
            console.log("LISTENING CLASS");
            channel.listen('Classifiers\\Updated', (e) => {
                let classifier = e.classifier
                classifier.exam_id = e.exam_id
                console.log(e)
                commit('add', classifier)
            })
            commit('channel', channel)
        },

    },
};

export default classifiers;
