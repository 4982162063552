<template>
    <BubbleContent  :title="`${$t('models.supervisor')}: `+ supervisor.name">
        <template #form>
            <p>
                {{ vTextAssignAnswer }}:
                <span>{{ group.supervisor_classifications }}</span>
            </p>
            <p>
                {{ vTextAssignAnswersNotClassified }}:
                <span>{{ group.supervisor_classifications - group.supervisor_classified }}</span>
            </p>
            <p>
                {{ vTextAnswersClassified }}:
                <span>{{ group.supervisor_classified }}</span>
            </p>
        </template>
    </BubbleContent>
</template>

<script>
import BubbleContent from '../../BubbleContent/BubbleContent.vue';

export default {
    data() {
        return {
            vTextAssignAnswer: `${this.$t('action.shaping.assignAnswers')}`,
            vTextAssignAnswersNotClassified: `${this.$t('action.shaping.assignAnswersNotClassified')}`,
            vTextAnswersClassified: `${this.$t('action.shaping.answersClassified')}`,
        }
    },
    props: {
        id: {
            required: true,
        }
    },
    components: {
        BubbleContent,
    },
    computed: {
        group() {
            return this.$store.getters['exams/groups/getId'](this.id)
        },
        supervisor() {
            if (this.group) {
                return this.$store.getters['users/getId'](this.group.user_id)
            }
            return null
        },
    },
}
</script>

<style lang="scss" scoped></style>
