<template>
    <div class="content-settings-nomenclature">
        <!-- <BubbleContent title="Classificadores" > -->
        <!-- <template #form> -->
        <p> Clique na palavra que pretende mudar e altere como necessário.</p>
        <div>
            <EditNames :label="`${$tc('models.exam')} =`" :value="name" v-model="name" />
        </div>
        <!-- </template> -->
        <!-- </BubbleContent> -->



    </div>
</template>
<script>
// import BubbleContent from '../BubbleContent/BubbleContent.vue';
import EditNames from './EditNames.vue';


export default {
    components: {
        // BubbleContent,
        EditNames,
    },
    data() {
        return {
            name: 'Mudar palavra aqui'
        }
    },
    methods: {},
    computed: {},
    mounted() {},
}

</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.content-settings-nomenclature {
    margin: 20px;
}
</style>