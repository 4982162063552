import StudentsPage from "../views/StudentsPage.vue";
import StudentList from "../components/SCOI/TabStudents/StudentList.vue";
import StudentEdit from "../components/SCOI/TabStudents/StudentEdit.vue";
import StudentCreate from "../components/SCOI/TabStudents/StudentCreate.vue";

const students = [
  {
    path: "/students",
    component: StudentsPage,
    children: [
      {
        path: "",
        name:'StudentList',
        component: StudentList,
      },
      {
        path: ":id",
        name: "StudentEdit",
        component: StudentEdit,
        props: true,
      },
      {
        path: "create",
        name: "StudentCreate",
        component: StudentCreate,
      },
    ],
  },
];

export default students;