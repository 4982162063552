<template>
    <div class="content-exams-create">
        <BubbleContent :title="$t('action.shaping.addExam')" :buttons="button" :loadingInfo="loadingInfo" @submit="submit">
            <template #form>
                <InputContent :label="$t('generic.name')" v-model="name" />
                <InputContent :label="$t('generic.table')" v-model="table" />
                <InputContent :label="$t('generic.studentColId')" v-model="studentId" />
                <InputSelectContent :label="$t('generic.type')" :options="options" :choose="$t('action.shaping.selectType')"
                    v-model="kind" />
                <InputContent :label="$t('generic.startAt')" type="datetime-local" v-model="starts_at" :choose="date" />
                <InputContent :label="$t('generic.endAt')" type="datetime-local" v-model="ends_at" :choose="date" />
            </template>
        </BubbleContent>
    </div>
</template>
<script>
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';
import InputContent from '@/components/SCOI/Inputs/InputContent.vue';
import InputSelectContent from '@/components/input/SelectComponent.vue';
import moment from 'moment'
import uuid4 from 'uuid4';


function formatDate(date) {
    return date.format("YYYY-MM-DD HH:mm:ss")
}

export default {
    components: {
        BubbleContent,
        InputContent,
        InputSelectContent,
    },
    data() {
        return {
            name: "",
            table: "",
            studentId: "",
            kind: '0',
            starts_at: '',
            ends_at: '',
            uid: null,
            options: [
                { text: this.$t('generic.regular'), value: '0' },
                { text: this.$t('generic.practical_m'), value: '1' },
            ],
            date: new Date().toLocaleString()
        };
    },
    methods: {
        submit() {
            this.$store.dispatch('exams/create', { examData: this.newExamData, uid: this.uid })
        },
    },
    computed: {
        button() {
            return [
                { text: this.$t('action.save'), type: 'submit', disabled: !this.canSubmit },
            ]
        },
        canSubmit() {
            return this.studentId && this.name && this.table
        },
        messages() {
            return this.$store.getters['loadingMessages/messages'](this.uid)
        },
        loadingInfo() {
            return this.messages
        },
        endsAtDate() {
            return formatDate(moment(this.ends_at))
        },
        lastExam() {
            return this.$store.getters['exams/last']
        },
        startsAtDate() {
            return formatDate(moment(this.starts_at))
        },
        newExamData() {
            return {
                name: this.name,
                table: this.table,
                student_col: this.studentId,
                kind: this.kind,
                starts_at: this.startsAtDate,
                ends_at: this.endsAtDate,
            }
        },
    },
    watch: {
        lastExam() {
            if (this.lastExam.created) {
                this.$router.push({ name: 'Exam', params: { id: this.lastExam.id } })
            }
        }
    },
    mounted() {
        this.starts_at = formatDate(moment());
        this.ends_at = formatDate(moment().add(1, "month"));
        this.uid = uuid4();
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';


.content-exams-create {
    margin: 20px;
    color: $black;

    :deep .field {
        margin-bottom: 1.5em;

        #role {
            color: $black;
            margin: 0;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            border: none;
            font-size: 100%;
            margin: 0;
            border-bottom: 4px solid $secondary-color;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }


        }
    }

}
</style>
