<template>
    <div class="content-classifications-input">
        <InputContent :label="`${$tc('models.classification')}`" />
        <InputContent :label="`${$t('action.shaping.doubleClassification')}`" />
    </div>
</template>
<script>
import InputContent from '../../Inputs/InputContent.vue';

export default {
    components: {
        InputContent,
    },

}
</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';

.content-classifications-input {
    width: 80%;
}
</style>