<template>
    <ul class="list">
        <li v-for="element in elements" :key="element.id">
            <router-link
                :to="routes[element.id]"
                v-text="element.name"
                class="button">
            </router-link>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            elements: {
                required: true,
            },
            routeName: {
            },
            routeId: {
            },
        },
        components: {
        },
        data() {
            return {
            }
        },
        computed: {
            routes() {
                let r = {name: this.routeName}
                let rs = {}
                for(let el of this.elements) {
                    rs[el.id] = {...r, params: {}}
                    rs[el.id].params[this.routeId] = el.id
                }
                return rs
            },
        },
        mounted() {
        }
    }
</script>

<style lang="scss" scoped>
@import "@/assets/utilities/variables";

.list {
    overflow: hidden scroll;
    border-right: 1px solid $dark-border-color;
}

.button {
    padding: .5em;
    font-size: 1rem;
    display: block;

    &.router-link-active {
        background-color: $secondary-color;
        font-weight: bold;
    }
}

</style>
