import MessagesView from "../views/MessagesView.vue";
// import ChannelView from "../views/messages/ChannelView.vue";

const exams = [
    {
        path: "/messages",
        component: MessagesView,
        props: true,
        name: "Messages",
    },
    {
        path: "/messages/:id/:channel_id?",
        component: MessagesView,
        props: true,
        name: "Channels",
    },
];

export default exams;
