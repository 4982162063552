export default {
    action: {
        decisive: {
            add: "Adicionar",
            back: "Voltar",
            before: "Anterior",
            cancel: "Cancelar",
            classify: "Classificar",
            change: "Alterar",
            create: "Criar",
            distribute: "Distribuir",
            download: "Descarregar",
            edit: "Editar",
            exit: "Sair",
            export: "Exportar",
            filter: "Filtrar",
            import: "Importar",
            login: "Entrar",
            next: "Próxima",
            redistribute: "Redistribuir",
            rememberMe: "Lembrar-me",
            remove: "Remover",
            reset: "Reiniciar",
            review: "Rever",
            save: "Gravar",
            saveForLater: "Gravar para ver mais tarde",
            select: "Selecionar",
            supervise: "Supervisionar",
            switch: "Trocar",
            update: "Atualizar",
        },
        shaping: {
            aboutToLogin: "A entrar...",
            addCode: "@:action.add @:generic.code",
            addColumn: "@:action.add @:generic.column",
            addDocument: "@:action.add @:models.document",
            addExam: "@:action.add @:models.exam",
            addFile: "@:action.add @:generic.file",
            addItem: "@:action.add @:models.item",
            addSchool: "@:action.add @:models.school",
            addStudent: "@:action.add @:models.student",
            addUser: "@:action.add @:models.user",
            answersClassified: "Respostas classificadas",
            areYouSureYouWantToDelete: "Têm a certeza que pretende remover ?",
            assignAnswers: "Respostas Atríbuidas",
            assignAnswersNotClassified: "Respostas Atríbuidas não classificadas",
            attributeOther: "Atribuir a Outro",
            classifiedAfterConflict: "@:generic.classified_f após conflito",
            classifiedBySupervisor: "Classificadas pelo Supervisor",
            classifyBySupervisors: "A Classificar por @:generic.supervisor",
            classifying: "A Classificar",
            changeCode: "Alterar Código",
            chooseAnOption: "Escolha uma opção",
            chooseAnOptionAdmin: "Administrador",
            chooseAnOptionIave: "IAVE",
            chooseAnOptionNotDefine: "Não Definido",
            chooseYourName: "Colocar nome pretendido",
            createGroup: "Criar Grupo",
            distributeClassification: "Distribuir Classificações",
            distributeItemsThroughClassifier: "Distribuir Itens por Classificador",
            doubleClassified: "Duplamente @:generic.classified_f",
            doubleClassification: "Dupla Classificação",
            editCode: "Editar Código",
            hideCriteria: "Ocultar Critérios",
            insertPdf: "Incluir aqui pdf de recurso",
            importAnswer: "Importar Respostas",
            itemToClassify: "{itemName}  ( A Classificar )",
            importClassifiers: "Importar Classificadores",
            lookForSupervisor: "Procure Supervisor",
            openInNewWindow: "Abrir numa nova janela",
            pointToReview: "Marcar para Revisão",
            pointedToReview: "Marcadas para Revisão",
            pressToChooseFile: "Carregue para escolher Ficheiro",
            processing: "A Processar...",
            removeGroup: "Remover Grupo",
            recoverPassword: "Recuperar @:generic.password",
            removeRestriction: "Remover Restrição",
            repeatPass: "Repetir @:generic.password",
            seeAll: "Ver Todos",
            seeCriteria: "Ver Critérios",
            seeLater: "Ver mais tarde",
            selectAllToDistribute: "Selecionar Todos",
            selectCodesToRestrict:
            "Selecione o(s) código(s) a que pretende adicionar uma restrição",
            selectDay: "@:action.select Dia",
            selectFile: "@:action.select Ficheiro",
            selectHour: "@:action.select Hora",
            selectMonth: "@:action.select Mês",
            selectPossibleCodes: "Selecione os códigos possíveis",
            selectSchool: "@:action.select Escola",
            selectStudent: "@:action.select Estudante",
            selectType: "@:action.select Tipo",
            selectYear: "@:action.select Ano",
            toClassifyAnswerSeeFileAttach:
            "Para classificar esta resposta, ver este ficheiro em anexo",
            tripleClassified: "Triplicamente @:generic.classified_f",
            updateGroup: "Atualizar Grupo",
            updateDoubleClassificationInfo:
            "Atualizar informação sobre dupla classificação",
            uploading: "A Carregar",
            uploaded: "Carregado",
            writeMessage: "Escrever Mensagem",
            waiting: "A Esperar",
        },
    },
    errors: {
        ERR_NETWORK: "Erro de Rede",
        ERR_BAD_REQUEST: "@:generic.password ou @:models.user Errado",
    },
    generic: {
        accessData: "Dados de Acesso",
        accessUserData: "Dados de Utilizador",
        accessLevel: "Nível de acesso",
        activeRestriction: "Restrições Ativas",
        attachment: "Anexo | Anexos",
        code: "Código | Códigos",
        codeSelected: "Códigos selecionados",
        confirmation: "Confirmação",
        classificationRestriction: "Restrições de Classificação",
        classified_f: "Classificada | Classificadas",
        classified_m: "Classificado | Classificados",
        classifiedTotal: "@:generic.classified_f / @:generic.total",
        classifierNumber: "Nr Classificador",
        column: "Coluna | Colunas",
        conclusion: "Conclusão",
        copywrite:
        "© IAVE 2018. Todos os acessos não autorizados a esta página são estritamente proibidos.",
        criteria: "Critério | Critérios",
        day: "Dia",
        developedBy: "Desenvolvido por BlatStudio",
        effort: "Esforço",
        effortIndex: "Índice de Esforço",
        email: "Email",
        endDate: "Fim",
        endAt: "Fim a",
        file: "Ficheiro",
        from: "Desde",
        group: "Grupo | Grupos",
        groupName: "Nome do Grupo",
        history: "Histórico",
        hour: "Hora",
        information: "Informação",
        itemAnswers: "@:modules.item [@:models.answer]",
        idNumber: "Número de Identificação",
        latex: "LaTex",
        lastEntry: "Última Entrada",
        progress: "Progresso",
        month: "Mês",
        multiparameter: "Multiparâmetro",
        multiparameterCodeExplanation:
        "Se este @:models.item de classificação tem vários parâmetros de classificação selecione o botão abaixo. Aqui pode ver todos os códigos atríbuidos a este Item de classificação. Para editar um código, selecione o código pretendido, altere os parâmetros do código nas caixas abaixo e clique no botão '@:action.changeCode' Para Remover um código, selecione o código pretendido, apague o texto do código e clique em '@:action.delete' código'",
        name: "Nome",
        missing: "Em falta",
        new: "Nova",
        newParameter: "Novo Parâmetro",
        nomenclature: "Nomenclatura",
        numberOfExams: "{nr} @:models.exam",
        numberOfSchools: "{nr} @:models.school",
        numberOfStudents: "{nr} @:models.student",
        numberOfUsers: "{nr} @:models.user",
        overlay: "Sobreposição",
        overlayPercentage: "Percentagem de @:generic.overlay",
        password: "Senha de Proteção",
        passwordInfo:
        "Se não desejar alterar a sua @.lower:generic.password deixe os campos para o efeito em branco",
        schoolCode: "Código Escola ",
        scoi: "SCOI",
        perfil: "Perfil",
        practical_f: "Prática",
        practical_m: "Prático",
        registered_f: "Registada | Registadas",
        registered_m: "Registado | Registados",
        registeredExams: "{nr} @:models.exam @:generic.registered_f",
        registeredSchools: "{nr} @:models.school @:generic.registered_f",
        registeredStudents: "{nr} @:models.student @:generic.registered_m",
        registeredUsers: "{nr} @:models.user @:generic.registered_m",
        regular: "Normal",
        saved: "Gravada | Gravadas",
        scoiVersion: "v3",
        securityCopy: "Cópia de Segurança",
        settings: "Definições",
        startAt: "Início a",
        startDate: "Começo",
        statistic: "Estatística",
        studentColId: "Coluna Id de Aluno",
        supervisorSelected:
        "@:models.supervisor Seleccionado | @:models.supervisor Selecionados",
        table: "Tabela",
        team: "Equipa | Equipas",
        teamIave: "Equipa IAVE",
        text: "Texto",
        title: "Título",
        total: "Total",
        totalEffort: "Esforço Total",
        type: "Tipo",
        tytle: "Título",
        until: "Até",
        value: "Valor",
        valuePercentage: "(@:generic.value %)",
        year: "Ano",
        word: "Palavra | Palavras",
    },
    models: {
        answer: "Resposta | Respostas",
        answer_nr: "Número da resposta",
        classification: "Classificação | Classificações",
        classifier: "Classificador | Classificadores",
        document: "Documento | Documentos",
        exam: "Prova | Provas",
        item: "Item | Itens",
        message: "Mensagem | Mensagens",
        school: "Escola | Escolas",
        student: "Estudante | Estudantes",
        submission: "Submissão | Submissões",
        supervisor: "Supervisor | Supervisores",
        user: "Utilizador | Utilizadores",
    },
};
