<template>
    <div v-if="exam" class="exam">
        <router-view v-if="isAdmin"></router-view>
        <ClassifierItems :id="id" :state="0" v-else/>
    </div>
</template>

<script>
import ClassifierItems from "@/components/exams/ClassifierItems.vue";

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        ClassifierItems,
    },
    data() {
        return {
        }
    },
    computed: {
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        classifier() {
            return this.$store.getters['exams/classifiers/exam'](this.id)
        },
        currentUser() {
            return this.$store.state.users.currentUser
        },
        isAdmin() {
            return this.currentUser != null && this.currentUser.role === 1
        },
    },
    watch: {
    },
    mounted() {
        this.$store.dispatch('exams/getId', this.id)
        this.$store.dispatch('exams/listen', this.id)
    },

}
</script>

<style lang="scss" scoped>
.exam {
    overflow: hidden;
}
</style>
