<template>
    <div class="message">
        <span class="username" :class="{current: currentUser}" v-text="username"></span>
        <span class="time" v-text="time"></span>
        <div class="body" v-html="body"></div>
    </div>
</template>

<script>
    import moment from "moment"

    export default {
        props: {
            message: {
                required: true,
            },
        },
        components: {
        },
        data() {
            return {
            }
        },
        computed: {
            body() {
                return this.message.body
            },
            currentUser() {
                return this.$store.state.users.currentUser == this.user
            },
            time() {
                return moment(this.message.created_at).locale('pt').calendar()
            },
            user() {
                return this.$store.getters['users/getId'](this.message.user_id)
            },
            username() {
                if( this.user == null ) {
                    return "..."
                }
                if( this.user.name ) {
                    return this.user.name
                }
                return this.user.nr_classificador
            },
        },
        mounted() {
        }
    }
</script>

<style lang="scss" scoped>
@import "@/assets/utilities/variables";

.message {
    margin-top: 1rem;

    .current {
        color: $primary-color;
    }
    .time {
        color: $dark-border-color;
        font-size: .9rem;
    }
    .username {
        font-weight: bold;
        margin-right: 2rem;
    }
}

</style>
