<template>
    <form class="form-resetPass" @submit.prevent="resetPass">
        <h1 v-text="`${$t('action.shaping.recoverPassword')}`"></h1>
        <div class="form-field">
            <label for="email" v-text="`${$t('generic.email')}`"></label>
            <input autofocus :model="$store.state.email" name="email" required type="email" />
        </div>
        <button class="form-submit" type="submit">{{ button }}</button>
    </form>
</template>
<script>
export default {
    props: ["button"],
    data() {
        return {};
    },
    methods:{
        resetPass(){
        },
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';


.form {
    &-resetPass {
        margin-right: 275px;
        display: flex;
        flex-direction: column;
        padding: 10px 0;

        h1 {
            font-size: 1.35em;
            text-transform: uppercase;
        }

        input {
            border: none;
            width: 235%;
            height: 3em;
            padding: 0 1em;
            border-bottom: 4px solid rgba(255, 255, 255, 0.6);
            background: none;
            margin-top: 10px;
            outline: none;
            color: $white;

            &:hover {
                border-bottom: 4px solid $white;
                transition: 0.2s border-color ease-out;
            }
        }
    }

    &-submit {
        position: relative;
        right: 35px;
        width: 310%;
        color: $primary-color;
        background-color: rgba(255, 255, 255, 0);
        text-transform: uppercase;
        border-color: transparent;
        border-top: 1px solid $primary-color;
        z-index: 1;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition-duration: 0.4s;
        line-height: 1;
        height: 2em;
        margin: -8px 0;
        margin-left: 20px;
        margin-top: 10px;


        &:hover {
            background-color: rgba($black, 0.261);
        }
    }
}
</style>