<template>
    <div>
        <TopContainerNav :title="`${$t('generic.history')}`" />
        <div>
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import TopContainerNav from '@/components/SCOI/TopContainerNav/TopContainerNav.vue';

export default {
    components: {
        TopContainerNav,
    },
    data() {
        return {};
    },
    
}
</script>
<style lang="scss" scoped>

</style>