<template>
    <BubbleContent :title="`${$t('action.decisive.import')}`" :buttons="button" :loadingInfo="loadingInfo" @submit="submit">
        <template #form>
            <div class="text">
                <p v-for="name in names" :key="name.text">
                    <span> | </span>
                    {{ name.text }}
                    <span> | </span>
                </p>
            </div>
            <InputFile :label="`${$t('action.shaping.selectFile')}`" v-model="files" />
        </template>
    </BubbleContent>
</template>
<script>
import BubbleContent from '../BubbleContent/BubbleContent.vue';
import InputFile from '../Inputs/InputFile.vue';
import uuid4 from 'uuid4';


export default {
    props: {
        names: {
            default: null,
        },
    },
    components: {
        BubbleContent,
        InputFile,
    },
    data() {
        return {
            files: [],
            uid: null,
        };
    },
    methods: {
        submit() {
            this.$store.dispatch('users/import', { file: this.file, uid: this.uid  })
            this.files = []
        },
    },
    computed: {
        button() {
            return [
                { text: `${this.$t('action.decisive.import')}`, type: 'submit', disabled: !this.canSubmit },
            ]
        },
        canSubmit() {
            return this.file != null
        },
        file() {
            return this.files[0]
        },
        loadingInfo() {
            return this.messages
        },
        messages() {
            return this.$store.getters['loadingMessages/messages'](this.uid)
        },
    },
    mounted() {
        this.uid = uuid4();

    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.text {
    display: flex;
    margin: 50px 0 20px 0;

    p {
        margin: 0 5px 0 5px;
    }
}
</style>