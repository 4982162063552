<template>
    <div class="body">
        <div class="title">
            <router-link v-if="goBackRoute" :to="goBackRoute">
                <span class="icon">{{ icon }}</span>
            </router-link>
            <h2>{{ title }}</h2>
        </div>

        <InfoLoading
        v-if="loading"
        :error="error"
        :errorMessage="errorMessage"
        :warning="warning"
        :warningMessage="warningMessage"
        />
        <div class="content">
            <p>{{ content }}</p>
            <slot></slot>
        </div>
        <div class="actions">
            <form @submit.prevent.stop="submit">
                <slot name="form"></slot>
                <button
                    v-for="button in buttons"
                    :key="button.text"
                    :disabled="button.disabled"
                    :type="button.type"
                    @click.prevent.stop="button.click ? onClick(button.click) : submit($event)">
                    <span class="button-text">{{ button.text }}</span>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import InfoLoading from "@/components/InfoLoading.vue"

export default {
    emits: ["submit"],
    data() {
        return {
        }
    },
    components: {
        InfoLoading,
    },
    props: {
        button: {
            type: Array,
        },
        buttons: {
            type: Array,
        },
        content: {
            type: String,
        },
        disabled: {
            default: null,
        },
        goBackRoute: {
            default: null,
        },
        icon: {
            type: String,
        },
        title: {
            type: String,
        },
        loading: {
            default: false,
        },
        loadingMessage: {
            default: '',
        },
        error: {
            default: false,
        },
        errorMessage: {
            default: '',
        },
        warning: {
            default: false,
        },
        warningMessage: {
            default: '',
        },
    },
    methods: {
        onClick(click) {
            click()
        }, 
        submit(event) {
            this.$emit('submit', event)

        },
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.actions {
    flex-shrink: 0;
}


.body {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba($black, .16), 0 3px 6px rgba($black, .23);
    min-height: 0;
    overflow: hidden;
    position: relative;

    .title {
        display: flex;
        align-items: center;
        padding-left: 15px;
        border-radius: 10px 10px 0 0;
        background: $primary-color;
        height: 40px;
        flex-shrink: 0;

        .icon {
            color: $white;
            font-size: 25px;
        }

        .material-icons {
            width: 20px;
            fill: $white;
            margin-top: 5px;
        }


        h2 {
            color: $white;
            margin: 0;
            font-size: 1.1rem;
            font-weight: 700;
            margin-left: 10px;
        }
    }

    .content {
        background: $white;
        min-height: 0;
        position: relative;
        flex-grow: 1;
        overflow: auto;
        

        p {
            color: $primary-color;
            margin: 0;
        }

    }

    form {
        :deep input:not([type=checkbox], [type=radio]) {
            border-radius: 0;
            background-color: $white;
            border: none;
            border-bottom: 4px solid $secondary-color;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            overflow: visible;
            font-family: sans-serif;
            font-size: 100%;
            line-height: 1.15;
            margin: 0;


            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }
        }

        button {
            width: 100%;
            text-transform: uppercase;
            border: none;
            height: 2em;
            border-top: 1px solid $primary-color;
            background-color: $white;
            position: relative;
            color: $primary-color;

            &:disabled {
                pointer-events: none;

                .button-text {
                    color: $light-border-color;
                }

            }

            .button-text {
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:before {
                    content: "";
                    background-image: linear-gradient(90deg, rgba($primary-color, 0), rgba($primary-color, 1), rgba($primary-color, 0));
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 0;
                    transition: opacity .2s ease;
                }


                &:hover {
                    transition: background-color .7s;
                    background: rgba($primary-color, .1);

                    &::before {
                        opacity: .1;
                    }
                }
            }
        }
    }
}
</style>
