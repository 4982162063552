<template>
    <div class="channel">
        <div class="channel_messages">
            <ul ref="messages">
                <li class="channel_lastmessage">
                    Não tem mais mensagens
                </li>
                <li v-for="message in messages" :key="message.id">
                    <MessageBox :message="message"/>
                </li>
            </ul>
            <form @submit.prevent="sendMessage">
                <input type="text" v-model="message" placeholder="Escreva aqui a sua mensagem">

                <div class="actions">
                    <label :for="fileLabel">
                        <IconComponent icon="clip" class="" @click.prevent="" />
                    </label>
                    <input type="file" :id="fileLabel" name="file">

                    <button type="submit">
                        <IconComponent icon="send" class="" @click.prevent="" />
                        Enviar
                    </button>
                </div>

            </form>
        </div>
        <div class="channel_participants">
            <h3>Participantes</h3>
            <ul>
                <li class='channel_participant' v-if="creator">
                    <span v-if="creator.name" v-text="creator.name"></span>
                    <span v-if="creator.name && creator.nr_classificador"> | </span>
                    <span v-if="creator.nr_classificador" v-text="creator.nr_classificador"></span>
                </li>
                <li v-for="user in users" :key="user.id" class='channel_participant'>
                    <span v-if="user.name" v-text="user.name"></span>
                    <span v-if="user.name && user.nr_classificador"> | </span>
                    <span v-if="user.nr_classificador" v-text="user.nr_classificador"></span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import IconComponent from '@/components/IconComponent.vue'
    import MessageBox from '../../components/messages/MessageBox.vue'
    import axios from '@/axiosInstance'

    export default {
        props: {
            channel_id: {
                required: true,
            },
        },
        components: {
            IconComponent,
            MessageBox,
        },
        data() {
            return {
                message: "",
            }
        },
        methods: {
            async sendMessage() {
                try {
                    await axios.post(
                        `${process.env.VUE_APP_API_HOST}/chats/${this.channel_id}`,
                        {
                            body: this.message,
                        }
                    );
                    this.message = ""
                } catch(e) {
                    console.log(e)
                }
            },
        },
        computed: {
            channel() {
                return this.$store.getters['messages/channel'](this.channel_id)
            },
            fileLabel() {
                return `file_${Math.random()}`
            },
            messages() {
                return this.$store.getters['messages/messages'](this.channel_id)
            },
            userIds() {
                if( this.channel == null ) {
                    return []
                }
                return this.channel.user_ids
            },
            users() {
                return this.$store.getters['users/withId'](this.userIds)
            },
            creator() {
                if( this.classifier == null ) {
                    return null
                }
                return this.$store.getters['users/getId'](this.classifier.user_id)
            },
            classifier() {
                if( this.channel == null ) {
                    return null
                }
                return this.$store.getters['exams/classifiers/getId'](this.channel.classifier_id)
            }
        },
        watch: {
            channel_id: {
                immediate: true,
                handler() {
                    this.$store.dispatch('messages/getMessages', this.channel_id)
                    this.$store.dispatch('messages/listenChannel', this.channel_id)
                },
            },
            channel: {
                immediate: true,
                handler() {
                    if( this.channel == null ) {
                        return
                    }
                    if( this.channel.classifier_id == null ) {
                        return
                    }
                    this.$store.dispatch('exams/classifiers/getId', this.channel.classifier_id)
                },
            },
            classifier: {
                immediate: true,
                handler() {
                    if( this.classifier == null ) {
                        return
                    }
                    this.$store.dispatch('users/getId', this.classifier.user_id)
                },
            },
            messages() {
                this.$nextTick().then( () => {
                    let el = this.$refs.messages
                    el.scrollTop = el.scrollHeight
                })
            },
            userIds() {
                for(let id of this.userIds) {
                    this.$store.dispatch('users/lowPriorityFetch', id)
                }
            },
        },
        mounted() {
        }
    }
</script>

<style lang="scss" scoped>
@import "@/assets/utilities/variables";

.channel {
    display: flex;
    background-color: $white;

    ul {
        margin: 0;
        overflow: auto;
        list-style: none;
        padding: 0;
    }

    &_lastmessage {
        margin-top: auto;
    }

    &_lastmessage {
        color: rgba($dark-border-color, .95);
        font-weight: bold;
    }

    &_messages {
        flex-grow: 4;
        display: flex;
        flex-direction: column;

        .actions {
            height: 3rem;
            margin: auto 0;
            display: flex;
            align-items: center;

            label, button {
                &:hover {
                    background-color: rgba($primary-color, .25);
                }
            }

            label {
                display: flex;
                height: 100%;
                align-items: center;
                padding: 0 1rem;

            }

            svg {
                height: 1.5rem;
                pointer-events: none;
                fill: $primary-color;
            }

            button {
                border: none;
                background: transparent;
                display: flex;
                align-items: center;
                gap: .5rem;
                color: $primary-color;
                padding: 0 1rem;
                height: 100%;
            }
        }

        ul {
            flex-grow: 1;
            overflow: auto;
            display: flex;
            flex-direction: column;
            padding: .75rem;
        }

        form {
            flex-shrink: 0;
            border-top: 1px solid $primary-color;
            background-color: $white;
            display: flex;
        }

        input {
            border: none;
            outline: none;
            padding: .75em;
            flex-grow: 1;

            &[type=file] {
                display: none;
            }
        }
    }

    &_participant {
        padding: .5em;
    }

    &_participants {
        flex-shrink: 0;
        border-left: 1px solid $dark-border-color;

        ul {
            font-weight: bold;
            font-size: .9rem;
        }

        h3 {
            margin: 0;
            flex-shrink: 0;
            background-color: $light-border-color;
            width: 100%;
            display: block;
            padding: .25rem .5rem;
            text-transform: uppercase;
            font-weight: normal;
            font-size: .85rem;
        }
    }
}

</style>
