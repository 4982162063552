<template>
    <div class="content-items-edit" v-if="item">
        <BubbleContent :buttons=button :title="item.name" icon="<"
            :goBackRoute="{ name: 'ExamItems', params: { id } }"
            :loadingInfo="loadingInfo"
            @submit=submit>
            <template #form>
                <InputContent :label="`${$tc('models.item')}`" v-model="name" />
                <InputContent :label="`${$t('generic.effortIndex')}`" type='number' v-model="effort" />
                <InputContent :label="`${$t('generic.overlay')}`" v-model="overflow" type='number' />
                <InputContent :label="`${$t('action.shaping.classifyBySupervisors')}`" type='number' v-model="supervisor_distribution" />
                <InputFile :label="`${$t('action.shaping.addFile')}`" v-model="crit" />
                <div class="radio-container">
                    <InputRadio name="option" class="radio-option" :label="`${$t('generic.latex')}`" v-model="latex" value="1" />
                    <InputRadio name="option" class="radio-option" :label="`${$t('generic.text')}`" v-model="latex" value="0" />
                </div>
                <div>
                    <p v-text="`${$tc('generic.column')}`"></p>
                    <p v-for="itemColumn in itemColumns" :key="itemColumn.id" class="box-container-column">
                        <span>{{ itemColumn }}</span>
                        <button class="btn-removeColumn" @click.prevent="removeColumn(itemColumn)" v-text="`${$t('action.decisive.remove')}`"></button>
                    </p>
                    <p v-text="`${$t('action.shaping.addColumn')}`"></p>
                    <input type="text" v-model="searchQuery">
                    <div>
                        <p v-for="col in columnItems" :key="col" :value="col" @click="addColumn(col)">
                            <span>{{ col }}</span>
                        </p>
                        <br>
                    </div>
                </div>
            </template>
        </BubbleContent>

        <br>

        <BubbleContent class="exams-edit-upload" title="Carregar Respostas" :buttons="[{text:'Upload'}]" @submit="upload">
            <template #form>
                <InputFile button="Anexos" label="Anexos" v-model="uploadFiles" :multiple="true"/>
                <div class="files" v-if="uploading.length">
                    <h3 v-text="uploadingText"></h3>
                    <ul>
                        <li v-for="file in uploading" :key="file.file.name">
                            {{ file.file.name }} - {{ file.status }}
                        </li>
                    </ul>
                </div>
            </template>
        </BubbleContent>

        <br>

        <ItemsCodes :itemId="itemId" />
    </div>
</template>
<script>
import BubbleContent from '../../BubbleContent/BubbleContent.vue';
import InputContent from '../../Inputs/InputContent.vue';
import InputFile from '../../Inputs/InputFile.vue';
import InputRadio from '../../Inputs/InputRadio.vue';
import ItemsCodes from './ItemsCodes/ItemsCodes.vue';
import uuid4 from 'uuid4';

import axios from "@/axiosInstance"

export default {
    components: {
        BubbleContent,
        InputContent,
        InputFile,
        InputRadio,
        ItemsCodes,
    },
    props: {
        itemId: {
            required: true
        },
        id: {
            required: true, //This id is to get the exam id
        },
    },
    data() {
        return {
            uid: null,
            crit: null,
            name: '',
            effort: 1,
            overflow: 10,
            supervisor_distribution: 100,
            latex: "0",
            itemColumns: [],
            searchQuery: "",
            columns: [],
            addItemButton: [
                { text: 'Gravar', type: 'submit' },
            ],
            uploading: [],
            uploaded: false,
            uploadFiles: null,
        };
    },
    methods: {
        addColumn(col) {
            this.itemColumns.push(col);
        },
        removeColumn(column) {
            this.itemColumns = this.itemColumns.filter(itemColumn => itemColumn !== column)
        },
        async upload() {
            this.uploaded = false
            this.uploading = []
            for( let file of this.uploadFiles ) {
                this.uploading.push({
                    file,
                    status: 'Em espera',
                    complete: false,
                    error: false,
                })
            }
            for( let file of this.uploading ) {
                let formData = new FormData()
                formData.append("upload", file.file)
                try {
                    file.status = "A carregar..."
                    let res = await axios.post(
                        `${process.env.VUE_APP_API_HOST}/exams/${this.id}/items/${this.itemId}/upload`,
                        formData,
                        {headers: {
                            'Content-Type': 'multipart/form-data'
                        }}
                    );
                    file.status = res.data.message
                    file.complete = true
                } catch(e) {
                    console.log(e)
                    file.status = e.response.data.message
                    file.error = true
                }
                this.uploaded = true
            }
        },
        submit() {
            const params = {
                exam: this.id,
                itemId: this.itemId,
                item: this.ItemData,
                uid: this.uid,
            }
            this.$store.dispatch('exams/items/edit', params)        
        },
    },
    computed: {
        allColumns() {
            if (this.exam) {
                return this.columns
            }
            return []
        },
        button() {
            return [
                { text: `${this.$t('action.decisive.save')}`, type: 'submit', disabled: !this.canSubmit },
            ]
        },
        canSubmit() {
            if( this.name != this.item.name ) {
                return true
            }
            if( this.effort != this.item.effort ) {
                return true
            }
            if( this.overflow != this.item.overflow ) {
                return true
            }
            if( this.supervisor_distribution != this.item.supervisor_distribution ) {
                return true
            }
            return true
        },
        messages() {
            return this.$store.getters['loadingMessages/messages'](this.uid)
        },
        loadingInfo() {
            return this.messages
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        item() {
            return this.$store.getters['exams/items/getId'](this.itemId)
        },
        ItemData() {
            return {
                name: this.name,
                effort: this.effort,
                columns: this.itemColumns,
                overflow: this.overflow,
                supervisor_distribution: this.supervisor_distribution,
                latex: this.latex,
            }
        },
        columnItems() {
            if (this.searchQuery) {
                return this.allColumns.filter((column) => column.toLowerCase().includes(this.searchQuery.toString().toLowerCase())).slice(0, 5)
            } else {
                return []
            }
        },
    },
    watch: {
        // o que inicia
        async crit() {
            if( this.crit != null ) {
                let file = this.crit[0]
                if( file == null ) {
                    return
                }
                let formData = new FormData()
                formData.append("file", file)
                try {
                    file.status = "A carregar..."
                    let res = await axios.post(
                        `${process.env.VUE_APP_API_HOST}/exams/${this.id}/items/${this.itemId}/criteria`,
                        formData,
                        {headers: {
                            'Content-Type': 'multipart/form-data'
                        }}
                    );
                    file.status = res.data.message
                    file.complete = true
                } catch(e) {
                    console.log(e)
                    file.status = e.response.data.message
                    file.error = true
                }
                this.uploaded = true
            }
        },
        item: {
            immediate: true,
            handler() {
                if (this.item != null) {
                    this.name = this.item.name
                    this.effort = this.item.effort
                    this.itemColumns = (typeof this.item.columns == "string") ? [this.item.columns] : this.item.columns
                    this.overflow = this.item.overflow
                    this.supervisor_distribution = this.item.supervisor_distribution
                    this.latex = this.item.latex.toString()
                }
            },
        }
    },
    async mounted() {
        this.uid = uuid4();
        if (this.item == null) {
            this.$store.dispatch('exams/items/getId', { exam: this.id, id: this.itemId })
        }
        const res = await axios.get(
            `${process.env.VUE_APP_API_HOST}/exams/${this.id}/columns`
        )
        this.columns = res.data.data;
    }
}
</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';

.content-items-edit {
    height: 100%;
    flex-grow: 1;
    margin: -1rem;
    padding: 1rem;
    overflow: auto;

    .material-icons {
        width: 20px;
        fill: rgba($black, 0.8);
    }

    .radio-container {
        display: flex;
    }


    .box-container-column {
        padding: 10px;
        width: 50%;
        box-shadow: 0px 2px 6px rgba($black, .4);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn-removeColumn {
            color: $red;
            border: none;
            border: 1px solid $red;
            cursor: pointer;
            padding: 5px;
            margin-left: 5px;
            border-radius: 5px;
            font-size: small;
    
            &:hover {
                background-color: $red;
                color: $white;
            }
        }
    }


    // .material-icons {
    //     width: 20px;
    //     fill: $red;
    //     margin-left: 10px;
    //     cursor: pointer;

    //     &:hover {
    //         font-size: larger;
    //     }
    // }
}
</style>
