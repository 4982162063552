<template>
    <TableCard :columns=columns>
        <form class="form">
            <InputSelectContent class="input" :label="`${$t('generic.year')}`" :choose="`${$t('action.shaping.selectYear')}`" :options="years" v-model="year" />
            <InputSelectContent class="input" :label="`${$t('generic.month')}`" :choose="`${$t('action.shaping.selectMonth')}`" :options="months" v-model="month" />
            <InputSelectContent class="input" :label="`${$t('generic.day')}`" :choose="`${$t('action.shaping.selectDay')}`" :options="days" v-model="day" />
            <InputSelectContent class="input" :label="`${$t('generic.hour')}`" :choose="`${$t('action.shaping.selectHour')}`" :options="hours" v-model="hour" />
            <div class="division-line">
                <h4 v-text="`${$t('generic.until')}:`"></h4>
            </div>
            <InputSelectContent class="input" :label="`${$t('generic.year')}`" :choose="`${$t('action.shaping.selectYear')}`" :options="years" v-model="year" />
            <InputSelectContent class="input" :label="`${$t('generic.month')}`" :choose="`${$t('action.shaping.selectMonth')}`" :options="months" v-model="month" />
            <InputSelectContent class="input" :label="`${$t('generic.day')}`" :choose="`${$t('action.shaping.selectDay')}`" :options="days" v-model="day" />
            <InputSelectContent class="input" :label="`${$t('generic.hour')}`" :choose="`${$t('action.shaping.selectHour')}`" :options="hours" v-model="hour" />
            <button class="filter-btn" v-text="`${$t('action.decisive.filter')}`"></button>
            <button class="download-btn" v-text="`${$t('action.decisive.download')}`"></button>
            <div>
                <p>sdafsadfadsf</p>
                <p>sdafsadfadsf</p>
                <p>sdafsadfadsf</p>
                <p>sdafsadfadsf</p>
                <p>sdafsadfadsf</p>
                <p>sdafsadfadsf</p>
            </div>
            <div class="buttons-content">
                <button class="buttons-beforeNext" @click.prevent="page -= 1" :disabled="page == 1">
                    <span v-text="`${$t('action.decisive.before')}`"></span>
                </button>
                <button class="buttons-beforeNext" @click.prevent="page += 1" :disabled="page == 0">
                    <span v-text="`${$t('action.decisive.next')}`"></span>
                </button>
            </div>
        </form>


    </TableCard>
</template>
<script>
import InputSelectContent from '@/components/input/SelectComponent.vue';
import TableCard from '@/components/TableCard.vue'


export default {
    props: {
        disabled: { default: null, type: String }
    },
    components: {
        InputSelectContent,
        TableCard,
    },
    data() {
        return {
            page: 0,
            year: '',
            month: '',
            day: '',
            columns: [
                { text: 'Desde: ' },
            ],
            buttons: [
                { text: 'Filtrar', type: 'submit' },
            ],
            years: [
                { text: '2023', value: '0' },
                { text: '2022', value: '1' },
                { text: '2021', value: '2' },
            ],
            days: [
                { text: '1', value: '0' },
                { text: '2', value: '1' },
                { text: '3', value: '2' },
            ],
            hours: [
                { text: '1', value: '0' },
                { text: '2', value: '1' },
                { text: '3', value: '2' },
            ],
            months: [
                { text: 'Janeiro', value: '0' },
                { text: 'Fevereiro', value: '1' },
                { text: 'Março', value: '2' },
                { text: 'Abril', value: '3' },
                { text: 'Maio', value: '4' },
                { text: 'Junho', value: '5' },
                { text: 'Julho', value: '6' },
                { text: 'Agosto', value: '7' },
                { text: 'Setembro', value: '8' },
                { text: 'Outubro', value: '9' },
                { text: 'Novembro', value: '10' },
                { text: 'Dezembro', value: '11' },
            ],
        }
    }

}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.form {

    .input {
        margin-top: 20px;
    }

    .division-line {
        padding: 0 0 0 1rem;
        border-bottom: 2px solid $primary-color;
    }

    .filter-btn {
        width: 100%;
        color: $primary-color;
        background-color: rgba(255, 255, 255, 0);
        text-transform: uppercase;
        border-color: transparent;
        padding: 0.4em;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition-duration: 0.7s;
        line-height: 1;
        height: 2em;
        display: inline-block;
        border-top: 1px solid $primary-color;
        position: relative;

        &:disabled {
            pointer-events: none;

            .button-text {
                color: $light-border-color;
            }
        }

        &:before {
            content: "";
            background-image: linear-gradient(90deg, rgba($primary-color, 0), rgba($primary-color, 1), rgba($primary-color, 0));
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            transition: opacity .2s ease;
        }


        &:hover {
            transition: background-color .7s;

            &::before {
                opacity: .25;
            }
        }
    }

    .download-btn {
        background-color: $primary-color;
        cursor: pointer;
        width: 100%;
        color: $white;
        padding: 0.5em;
        border: 1px solid $primary-color;
    }

    .buttons-content {
        display: flex;
        justify-content: space-between;
        padding: 0 20%;
        border-top: 1px solid $primary-color;
        // border-bottom: 1px solid $primary-color;

        .buttons-beforeNext {
            background: rgba($black, 0);
            border: none;
            display: inline-block;
            text-transform: uppercase;
            margin: 5px 0;
            cursor: pointer;
            transition-duration: 0.7s;
            position: relative;
            width: 100%;

            &:before {
                content: '';
                background-image: linear-gradient(90deg, rgba($primary-color, 0), rgba($primary-color, 1), rgba($primary-color, 0));
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0;
                transition: opacity .2s ease;
            }

            &:hover {
                transition: background-color .7s;

                &::before {
                    opacity: .25;
                }
            }
        }
    }
}
</style>
