<template>
    <div class="menu">
        <template v-for="action in actions" :key="action.text">
            <router-link :to="action.route" class="button">
                <span class="text">{{ action.text }}</span>
            </router-link>
        </template>
    </div>
</template>
<script>
export default {
    props: {
        actions: {
            default: null
        },
    },

}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';


.menu {
    background: $white;
    display: flex;
    align-items: center;
    border-bottom: 2px solid rgba($black, .3);
    width: 100%;
    max-width: 100%;

    .button {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
        border-left: 1px solid transparent;
        background-color: rgba(79, 90, 90, 0);
        padding: 10px;



        .text {
            color: rgba($black, 0.8);
            font-size: 1rem;
            font-weight: 700;

            &:active {
                color: $primary-color;
            }
        }

        &:hover {
            background-color: rgba($black, .05);
            color: $black;
        }

        &:focus,
        &.router-link-exact-active {
            border-bottom: 2px solid $primary-color;

            .text {
                color: $primary-color;
                fill: $primary-color;

            }
        }
    }
}
</style>
