<template>
    <div class="body">
        <div class="title">
            <router-link v-if="goBackRoute" :to="goBackRoute">
                <span class="icon">{{ icon }}</span>
            </router-link>
            <h2>{{ title }}</h2>
        </div>
        <div class="content">
            <p>{{ content }}</p>
            <slot></slot>
            <form @submit.prevent.stop="submit">
                <slot name="form"></slot>
                <div class="buttons">
                </div>
            </form>
        </div>
        <LoadingSpin v-if="loading" :info="_loadingInfo" />
        <button
            v-for="button in buttons"
            :key="button.text"
            :disabled="button.disabled"
            :type="button.type"
            @click.prevent.stop="button.click ? onClick(button.click) : submit($event)">
            <span class="button-text">{{ button.text }}</span>
        </button>
    </div>
</template>

<script>
import LoadingSpin from '../LodingSpin/LoadingSpin.vue'

export default {
    props: {
        button: {
            type: Array,
        },
        buttons: {
            type: Array,
        },
        content: {
            type: String,
        },
        disabled: {
            default: null,
        },
        goBackRoute: {
            default: null,
        },
        icon: {
            type: String,
        },
        title: {
            type: String,
        },
        loadingInfo: {
            default: {},
        },
    },
    emits: ["submit"],
    data() {
        return {
            loading: false,
        }
    },
    components: {
        LoadingSpin,
    },
    methods: {
        onClick(click) {
            this.loading = true
            click()
        }, 
        submit(event) {
            this.$emit('submit', event)
            this.loading = true

        },
    },
    computed: {
        _loadingInfo() {
            return {
                timeout: 3000,
                done: false,
                message: this.$t('action.shaping.uploading'),
                ...this.loadingInfo,
            }
        },
    },
    watch: {
        loadingInfo() {
            if (this.loadingInfo == null) {
                return
            }
            if (this.loadingInfo.done) {
                let timeout = this.loadingInfo.timeout != null ? this.loadingInfo.timeout : 3000
                setTimeout(() => this.loading = false, timeout)
            }
            this.$store.commit("loadingMessages/clearMessage", this.loadingInfo.uid )
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.body {
    --padding: 20px;

    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    max-height: 100%;
    border-radius: var(--padding);
    box-shadow: 0 3px 6px rgba($black, .16), 0 3px 6px rgba($black, .23);
    overflow: hidden;
    position: relative;

    .title {
        display: flex;
        align-items: center;
        padding-left: 15px;
        border-radius: 10px 10px 0 0;
        background: $primary-color;
        height: 40px;
        flex-shrink: 0;

        .icon {
            color: $white;
            font-size: 25px;
        }

        .material-icons {
            width: 20px;
            fill: $white;
            margin-top: 5px;
        }


        h2 {
            color: $white;
            margin: 0;
            font-size: 1.1rem;
            font-weight: 700;
            margin-left: 10px;
        }
    }

    .content {
        border-radius: 0 0 var(--padding) var(--padding);
        background: $white;
        padding: 0 var(--padding);
        position: relative;
        flex-grow: 1;
        overflow: auto;

        p {
            color: $primary-color;
            margin: 0;
        }

    }

    form {
        :deep input:not([type=checkbox], [type=radio]) {
            border-radius: 0;
            background-color: $white;
            border: none;
            border-bottom: 4px solid $secondary-color;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            overflow: visible;
            font-family: sans-serif;
            font-size: 100%;
            line-height: 1.15;
            margin: 0;


            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }
        }

    }

    button {
        width: 100%;
        text-transform: uppercase;
        border: none;
        height: 2em;
        background-color: $white;
        position: relative;
        color: $primary-color;
        padding: 0;

        &:disabled {
            pointer-events: none;

            .button-text {
                color: $light-border-color;
            }
        }

        &:before {
            content: "";
            background-image: linear-gradient(90deg,
                rgba($primary-color, 0) 5%,
                rgba($primary-color, .75) 40%,
                rgba($primary-color, .75) 60%,
                rgba($primary-color, 0) 95%
                );
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            transition: opacity .2s ease-out;
            z-index: 1;
        }


        &:hover {
            transition: background-color .7s;
            color: $white;

            &::before {
                opacity: 1;
            }
        }

        .button-text {
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;
            transition: color .2s ease0out;

        }
    }
}

.buttons {
    border-top: 1px solid $primary-color;
    display: flex;
    margin: 0 calc(var(--padding) * -1);
    border-radius: 0 0 var(--padding) var(--padding);
    overflow: hidden;
}

</style>
