import ExamPageInfo from "@/views/exams/ExamView.vue";
import ExamAnswers from '../components/SCOI/TabExams/ExamAnswers/ExamAnswers.vue'
import ExamStatistics from '../components/SCOI/TabExams/ExamStatistics/ExamStatistics.vue'

const examInfo = [
  {
    path: "/exam/:id",
    component: ExamPageInfo,
    props: true,
    children: [
      {
        path: "answers",
        name: "ExamAnswers",
        component: ExamAnswers,
      },
      {
        path: "statistics",
        name: "ExamStatistics",
        component: ExamStatistics,
      },
    ],
  },
];

export default examInfo;
