<template>
    <div class="items-distribution-info">
        <p class="info-group" v-text="`${$t('generic.group')}:`"></p>
        <span class="info-group-name" v-if="group">{{ group.name }}</span>
        <p class="info-group" v-text="`${$tc('models.supervisor')}:`"></p>
        <span class="info-group-name" v-if="supervisor">{{ supervisor.name }}</span>
    </div>
    <div ref="checkboxes" v-if="ready">
        <p class="items-classifiers" v-for="classifier in classifiers" :key="classifier.id">
            <span>{{ classifier.name }}</span>
            <span class="classifiers-checkbox" v-for="item in items" :key="item.id">
                <input type="checkbox" v-model="checked[item.id][group.classifierIds[classifier.id]]">
            </span>
        </p>
        <button class="button">
            <input type="checkbox" v-model="select_all">
            <span v-text="`${$t('action.shaping.selectAllToDistribute')}:`"></span>
        </button>
    </div>
</template>
<script>
export default {
    props: {
        id: {
            required: true,
        }
    },
    emits: {
        update: null,
    },
    data() {
        return {
            select_all: false,
            checked: {},
        }
    },
    computed: {
        classifiers() {
            if (this.group) {
                return this.$store.getters['users/withId'](this.group.users)
            }
            return []
        },
        items() {
            if (this.group) {
                return this.$store.getters['exams/items/byExamId'](this.group.exam_id)
            }
            return []
        },
        group() {
            return this.$store.getters['exams/groups/getId'](this.id)
        },
        ready() {
            return this.group != null && 
                this.classifiers != null &&
                this.items != null &&
                this.supervisor != null
        },
        supervisor() {
            if (this.group) {
                return this.$store.getters['users/getId'](this.group.user_id)
            }
            return null
        },
        updatedAttributions() {
            let attributions = {}
            for(let itemId in this.checked ) {
                attributions[itemId] = Object.keys(this.checked[itemId]).reduce( (classifierIds, classifierId) => {
                    if( this.checked[itemId][classifierId] ) {
                        classifierIds.push(classifierId)
                    }
                    return classifierIds
                }, [])
            }
            return attributions
        },
    },
    watch: {
        updatedAttributions: {
            deep: true,
            handler() {
                this.$emit("update", this.updatedAttributions)
            }
        },
        ready: {
            immediate: true,
            handler() {
                if( this.group == null ) {
                    return
                }
                for( let item of this.items ) {
                    this.checked[item.id] = {}
                    for( let userId in this.group.classifierIds ) {
                        let classifierId = this.group.classifierIds[userId]
                        this.checked[item.id][classifierId] = false // TODO: should be the value from API
                    }
                }
            }
        },
        select_all: {
            handler() {
                for( let itemCheckbox in this.checked ) {
                    for(let classifierCheckbox in this.checked[itemCheckbox] ) {
                        this.checked[itemCheckbox][classifierCheckbox] = this.select_all
                    }
                }
            }
        },
    },
    mounted() {},

}
</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';

.items-distribution-info {
    border-top: 1px solid $dark-border-color;


    .info-group {
        font-weight: bold;
        margin: 0;

        .info-group-name {
            font-weight: lighter;
        }
    }
}

.items-classifiers {
    border-bottom: 1px solid $dark-border-color;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: 10px 0 0 0;


    .classifiers-checkbox {
        cursor: pointer;
        margin: 0 0 23px 0;
        transform: scale(2.5);
        display: inline-block;
        height: 1em;
        width: 1em;
        accent-color: $primary-color ;

    }

}

.button {
    background: $primary-color;
    cursor: pointer;
    color: $white;
    padding: 0.7em;
    transition: .2s ease-out;
    width: 100%;
    transition-property: background-color, color, border-color;
    border: 1px solid $primary-color;
    border-collapse: collapse;
}
</style>
