<template>
    <div class="home">
        <div class="home_content">
            <CardTitle v-if="exams"
            :title="`${$tc('generic.registeredExams', exams.length, {nr: exams.length} )}`">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <router-link :to="{name: 'ExamsIndex'}">
                                    <strong v-text="`${$t('action.shaping.seeAll')}`"></strong>
                                </router-link>
                            </td>
                        </tr>
                        <tr v-for="exam in exams" :key="exam.id">
                            <td>
                                <router-link v-text="exam.name" :to="{name: 'Exam', params: {id: exam.id}}">
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </CardTitle>
            <router-link to="/users" v-if="isAdmin">
                <BubbleContent v-if="users" :title="`${$tc('models.user', users.length )}`" :content="`${$tc(`generic.registeredUsers`, users.length, {nr: users.length})}`"/>
            </router-link>
            <router-link to="/schools" v-if="isAdmin">
                <BubbleContent v-if="schools" :title="`${$tc('models.school', schools.length )}`" :content="`${$tc(`generic.registeredSchools`, schools.length, {nr: schools.length})}`"/>
            </router-link>
            <router-link to="/students" v-if="isAdmin">
                <BubbleContent v-if="students" :title="`${$tc('models.student', students.length )}`" :content="`${$tc(`generic.registeredStudents`, students.length, {nr: students.length})}`"/>
            </router-link>
        </div>
    </div>
</template>

<script>
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';
import CardTitle from '@/components/CardTitle.vue';

export default {
    components: {
        BubbleContent,
        CardTitle,
    },
    computed: {
        currentUser() {
            return this.$store.state.users.currentUser
        },
        isAdmin() {
            return this.currentUser != null && this.currentUser.role === 1
        },
        exams() {
            return this.$store.state.exams.all;
        },
        schools() {
            return this.$store.state.schools.all;
        },
        students() {
            return this.$store.state.students.all;
        },
        users() {
            return this.$store.state.users.all;
        },
    },
    mounted() {
        this.$store.dispatch('exams/getAll')
        this.$store.dispatch("users/getAll");
    },

}
</script>
<style lang="scss" scoped>
@import "@/assets/utilities/variables";

.home {
    position: relative;
    flex-grow: 1;
    align-self: flex-start;
    flex-direction: column;

    &_content {
        gap: 1rem;
        padding: 1rem;
        display: flex;
        flex-wrap: wrap;
        grid-template-columns: repeat(4, 1fr);
    }
}

h3 {
    margin: 0 0 1em;
}

table {
    width: calc(100% + 2rem);
    margin: -1rem;
    border-collapse: collapse;

    tr {
        transition: .2s ease-out background-color;

        &:hover {
            background-color: rgba($primary-color, 0.1);
        }

        td {
            a {
                width: 100%;
                height: 2.5rem;
                padding: 0 1rem;
                display: flex;
                align-items: center;
            }
        }
    }
}
</style>
