<template>
    <div class="field">
        <label for="title">{{ label }}</label>
        <input 
        :value="modelValue" 
        required 
        :type="type" 
        @input="$emit('update:modelValue', $event.target.value)"/>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                default: 'text',
            },
            modelValue: {
                default: '',
            },
            choose: {
                default: '',
            },
        },
        emits: [
            'update:modelValue',
        ],
    }
</script>

<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.field {
    margin-bottom: 1.5em;
    margin-top: 20px;

    input {
        color: $black;
        margin: 0;
        width: 100%;
        height: 3em;
        padding: 0 1em;
        outline: none;
        appearance: none;
        border: none;
        font-size: 100%;
        margin: 0;
        border-bottom: 4px solid $secondary-color;

        &:focus {
            border-bottom: 4px solid $primary-color;
            transition: border-color .2s ease-out;

        }
    }
}

option{
    position: relative;
    top: 32px;
}

</style>
