<template>
    <div class="users">
        <TableCard :columns="columns" :rows="rows" class="table" />
        <DeletePopup v-show="userToDelete != null" @cancel="closePopup" @execute="deleteUser" />
    </div>
</template>
<script>
    import TableCard from '@/components/TableCard.vue';
    import DeletePopup from '../DeletePopup/DeletePopup.vue'

export default {
    components: {
        TableCard,
        DeletePopup,
    },
    props: {},
    data() {
        return {
            columns: [
                { text: `${this.$t('generic.name')}` },
                { text: `${this.$t('generic.email')}` },
                { text: `${this.$t('generic.classifierNumber')}` },
                { text: `${this.$t('generic.lastEntry')}` },
                { text: `` },
            ],
            userToDelete: null,
        };
    },
    methods: {
        closePopup() {
            this.userToDelete = null;
        },
        deleteUser() {
            this.$store.dispatch('users/delete', this.userToDelete.id)
        },
        showPopup(user) {
            this.userToDelete = user;
        },
    },
    computed: {
        users() {
            return this.$store.state.users.all;
        },
        rows() {
            return this.users.map( (user) => [
                {text: user.name, link: {name: 'UserEdit', params: {id: user.id} } },
                {text: user.email, link: {name: 'UserEdit', params: {id: user.id} } },
                {text: user.nr_classificador, link: {name: 'UserEdit', params: {id: user.id} } },
                {text: user.updated_at },
                {icon: 'trash', action: () => this.showPopup(user) },
            ])
        },
    },
    mounted() {
        this.$store.dispatch("users/getAll");
    },
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.users {
    padding: 1rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;

    .table {
        width: 100%;
        overflow: auto;
        flex-grow: 1;
    }

}


.users-container {

    background-color: $white;
    padding: 0 20px;
    margin: 20px;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgba($black, .16), 0 3px 6px rgba($black, .23);


    .users-items {
        padding: 1rem;
        border-bottom: 2px solid $primary-color;
        flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(5, 1fr);

        p {
            margin: 0;
            font-weight: bold;
        }
    }

    .list {
        margin: 0;
        padding-left: 1rem;
        border-top: 1px solid $black;

        &:hover {
            background: rgba($primary-color, 0.1);
        }

        .users {
            display: grid;
            flex-wrap: wrap;
            grid-template-columns: repeat(5, 1fr);

            .btn-removeUsers{
            color: $red;
            border: none;
            border: 1px solid $red;
            cursor: pointer;
            padding: 5px;
            margin-left: 5px;
            border-radius: 5px;
            font-size: small;
            margin: auto;

            &:hover{
                background-color: $red;
                color: $white;
            }
            }
            .material-icons {
                margin-top: 15px;
                width: 20px;
                fill: $red;
                margin-left: 50px;

                &:hover {
                    margin-top: 12px;
                    font-size: larger;
                }
            }


            span {
                margin-top: 15px;
                color: $red;
                z-index: 1;

                &:hover {
                    margin-top: 12px;
                    font-size: larger;
                }


            }
        }

    }
}
</style>
