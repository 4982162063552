import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'
import 'normalize.css'

import messages from './messages/messages'
const i18n = createI18n({
    locale: 'pt',
    fallbackLocale: 'en',
    messages,
})

createApp(App)
.use(store)
.use(router)
.use(i18n)
.mount('#app')

export default { store }
