<template>
    <aside :class="`${isExpanded ? 'is-expanded' : ''}`">
        <div class="container">
            <router-link to="/home">
                <div class="logo">
                    <img :src="logo" alt="logo" />
                </div>
            </router-link>

            <div class="menu">
                <span class="toggle" @click="toggleSideBar">
                    <IconComponent class="material-icons" icon="arrows-left" />
                </span>
                <router-link to="/exams" class="button">
                    <IconComponent class="material-icons" icon="clipboard" />
                    <span class="text" v-text="`${$tc('models.exam', 2)}`"></span>
                </router-link>

                <router-link to="/users" class="button" v-if="isAdmin">
                    <IconComponent class="material-icons" icon="users" />
                    <span class="text" v-text="`${$tc('models.user', 2)}`"></span>
                </router-link>

                <router-link to="/schools" class="button" v-if="isAdmin">
                    <IconComponent class="material-icons" icon="university" />
                    <span class="text" v-text="`${$tc('models.school', 2)}`"></span>
                </router-link>

                <router-link to="/students" class="button" v-if="isAdmin">
                    <IconComponent class="material-icons" icon="grad-hat" />
                    <span class="text" v-text="`${$tc('models.student', 2)}`"></span>
                </router-link>

                <router-link :to="{name: 'Messages'}" class="button" >
                    <IconComponent class="material-icons" icon="chat" />
                    <span class="text" v-text="`${$tc('models.message', 2)}`"></span>
                </router-link>

                <router-link to="/documents" class="button">
                    <IconComponent class="material-icons" icon="documents" />
                    <span class="text" v-text="`${$t('models.document')}`"></span>
                </router-link>

                <router-link to="/definicoes" class="button" v-if="isAdmin">
                    <IconComponent class="material-icons" icon="gear" />
                    <span class="text" v-text="`${$t('generic.settings')}`"></span>
                </router-link>

                <router-link to="/history" class="button" v-if="isAdmin">
                    <IconComponent class="material-icons" icon="history" />
                    <span class="text" v-text="`${$t('generic.history')}`"></span>
                </router-link>
            </div>
        </div>
    </aside>
</template>

<script>
    import IconComponent from '@/components/IconComponent.vue'
    import logo from '@/assets/images/logo_web.png'

    export default {
        components: {
            IconComponent,
        },
        data() {
            return {
                logo: logo,
                isExpanded: true,
            };
        },
        methods: {
            toggleSideBar() {
                this.isExpanded = !this.isExpanded
            },
        },
        computed: {
            currentUser() {
                return this.$store.state.users.currentUser
            },
            isAdmin() {
                return this.currentUser != null && this.currentUser.role == 1
            },
        }
    }
</script>
<style lang="scss" scoped>
@import '../assets/utilities/_variables.scss';

aside {
    display: flex;
    flex-direction: column;
    background-color: $sidebar-bg-color;
    color: $primary-color;
    overflow: hidden;
    transition: 0.2s ease-in-out;
    border-right: 1px solid $dark-border-color;
    width: $sidebar-width;
    transition: width .2s ease-out;
    position: relative;
    z-index: 1000;

    .container {
        width: max-content;

        .logo {
            position: relative;
            margin: 0;
            padding: 40px 20px;
            text-align: center;

            img {
                margin: auto;
                max-width: 150px;
                display: block;
                border-style: none;
            }
        }

        .menu {

            .toggle {
                fill: rgba($black, 0.8);
                cursor: pointer;
                display: flex;
                margin-left: 15px;

                .material-icons {
                    width: 20px;
                    fill: rgba($black, 0.8);
                }

                &:hover .material-icons {
                    fill: $primary-color;
                }

            }


            .button {
                text-decoration: none;
                display: flex;
                align-items: center;
                width: auto;
                height: 50px;
                padding-left: 12px;
                border-left: 5px solid transparent;
                background-color: rgba(79, 90, 90, 0);


                .material-icons {
                    width: 20px;
                    fill: rgba($black, 0.8);
                    margin-right: 12px;

                }

                .text {
                    color: rgba($black, 0.8);
                    font-size: 1rem;

                    &:active {
                        color: $primary-color;
                    }
                }


                &:hover {
                    background: rgba($primary-color, 0.1);
                    color: $black;
                }

                &:focus,
                &.router-link-active {
                    border-left: 5px solid $primary-color;

                    .material-icons,
                    .text {
                        color: $primary-color;
                        fill: $primary-color;


                    }
                }

            }

        }


    }

}

.is-expanded {
    width: $sidebar-collapsed-width;

    .logo {
        margin: 0;
        padding: 0;
        text-align: center;



        img {
            position: absolute;
            border-style: none;
            width: 80px;
            left: 3px;
        }
    }

    .menu {
        margin-top: 30px;


        .toggle {
            width: 20px;
            fill: rgba($black, 0.8);
            cursor: pointer;
            margin-bottom: 5px;

            .material-icons {
                width: 20px;
                fill: rgba($black, 0.8);
                transform: rotate(-180deg);
            }

        }

        .button {
            display: flex;
            align-items: center;
            padding-left: 12px;
            border-left: 5px solid transparent;
            background-color: rgba(79, 90, 90, 0);

            .material-icons {
                width: 20px;
                fill: rgba($black, 0.8);
                margin-right: 12px;

            }

            .text {
                opacity: 0;
                transition: visibility 1s, opacity 0.3s linear;

            }

        }
    }
}
</style>
