import HistoryPage from "../views/HistoryPage.vue";
import HistoryList from "../components/SCOI/TabHistory/HistoryList.vue";

const history = [
  {
    path: "/history",
    component: HistoryPage,
    children: [
      {
        path: "",
        name:'HistoryList',
        component: HistoryList,
      },
    ],
  },
];

export default history;