import axios from '@/axiosInstance'
import theEcho from '@/echoInstance'

const exams = {
    namespaced: true,
    state: {
        all: [],
        channel: null,
        gotAll: false,
        exam: null,
        examExportFiles: [],
        examExportFilesDownload: [],
    },
    getters: {
        classifier: (state) => (id) => {
            return state.all.filter( attr => attr.classifier_id == id );
        },
        exam: (state) => (id) => {
            id = parseInt(id)
            let attrs = state.all.filter((attr) => attr.exam_id === id)
            return attrs.sort( (a,b) => a.id - b.id )
        },
        item: (state) => (id) => {
            id = parseInt(id)
            return state.all.find((attr) => attr.exam_item_id === id)
        },
        last(state) {
            return state.all.reduce( (pr, curr, index) => {
                if( index == 0 ) return curr
                if( pr.id > curr.id ) return pr
                return curr
            }, null )
        },
        examChannel: (state) => (id) => {
            let channelData = state.examChannels.find((data) => data.id == id)
            if( channelData == null ) {
                return null
            }
            return channelData.channel
        },
        exportFiles: (state) => (id) => {
            const examFiles = state.examExportFiles.find((files) => parseInt(files.id) === parseInt(id));
            if( examFiles ) {
                return examFiles.files
            }
            return []
        },
    },
    mutations: {
        add(state, attr) {
            let index = state.all.findIndex( ({id}) => attr.id == id )
            if( index < 0 ) {
                state.all.push(attr)
                return
            }
            state.all.splice(index, 1, attr)
        },
        all(state, exams) {
            state.all = exams;
            state.gotAll = true;
        },
        channel(state, channel) {
            state.channel = channel
        },
        clear(state) {
            state.all = []
            state.exam = null
            state.gotAll = false
        },
        delete(state, exam) {
            const index = state.all.findIndex(({ id }) => exam.id === id);
            state.all.splice(index, 1);
        },
        exam(state, exam) {
            const index = state.all.findIndex(({ id }) => exam.id === id);
            if (index < 0) {
                state.all.push(exam);
            } else {
                exam = {...state.all[index], ...exam}
                state.all.splice(index, 1, exam);
            }
        },
        examExportFiles(state, {id, files}) {
            const index = state.examExportFiles.findIndex(files => files.id === id);
            if (index < 0) {
                state.examExportFiles.push({id, files});
            } else {
                state.examExportFiles.splice(index, 1, {id, files});
            }
        },
        examExportFilesDownload(state, {id, downloading}) {
            const index = state.examExportFilesDownload.findIndex(fileId => fileId === id);
            if (index < 0 && downloading) {
                state.examExportFilesDownload.push(id);
            } else if( index >= 0 && !downloading ) {
                state.examExportFilesDownload.splice(index, 1);
            }
        },
        update(state, {itemId, o, n} ) {
            let index = state.all.findIndex( ({exam_item_id}) => itemId == exam_item_id )
            if( index < 0 ) {
                return
            }
            let attr = state.all[index]
            console.log(attr)
            console.log(o)
            console.log(n)
            state.all.splice(index, 1, {...attr})
        },
    },
    actions: {
        async getAll({ state, commit }, exam) {
            if( state.gotAll ) {
                return
            }
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/${exam}/attributions`
                );
                for(let attr of results.data.data) {
                    commit("add", attr);
                }
            } catch (error) {
                console.log("ERROR: " + error);
                commit('clear')
            }
        },
        // GET EXAM BY ID ACTION
        async getId({ commit }, id) { // TODO: I believe this function is not used
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/${id}`
                );
                let exam = results.data.data;
                commit("exam", exam);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async getExportFiles({commit}, id) {
            try {
                const res = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/${id}/export`
                );
                commit("examExportFiles", {id, files: res.data.data});
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        // EXPORT EXAM ACTION
        async export({id}) {
            try {
                const res = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/exams/${id}/export`,
                );
                console.log(res.data.data)
            } catch(error) {
                console.log("ERROR:" + error)
            }
        },
        // IMPORT EXAM ANSWERS ACTION
        async import({ commit }, { id, file, attachment, uid }) {
            try {
                let formData = new FormData();
                formData.append("file", file);
                formData.append("attachment", attachment);
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/exams/${id}/import`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                let exam = results.data.data
                commit('exam', exam)
                let successObj = {
                    message: results.data.message,
                    error: false,
                    done: true,
                    uid,
                };
                commit("loadingMessages/messages", successObj, { root: true });
            } catch(error) {
                console.log("ERROR:" + error)
                let errorObj = error.response.data;
                let errorMessage = errorObj.message;
                errorObj = { message: errorMessage, error: true, done: true, uid };
                commit("loadingMessages/messages", errorObj, { root: true });
            }
        },

        listen({state, commit}, user_id) {
            if( state.channel != null ) {
                return
            }
            let channel = theEcho.echo.private(`item-attributions.${user_id}`)
            console.log("Listening to item attribution2 channel")
            channel.listen('ItemAttributions\\Updated', (e) => {
                let attr = e.attribution
                attr.exam_id = e.exam_id
                console.log(e)
                commit('add', attr)
            })
            commit('channel', channel)
        },

        // RESET EXAM STATE ACTION
        async reset({ commit }, id) {
            const res = await axios.post(
                `${process.env.VUE_APP_API_HOST}/exams/${id}/reset`
            );
            commit("exam", res.data.data);
        },
    },
};

export default exams;
