<template>
    <div class="classifiers-items-container">
        <BubbleContent v-if="exam" :title="`${$t('action.shaping.classifying')} - ` + exam.name">
            <p class="classifiers-items-title" v-text="`${$tc('models.item')} [ ${$tc('models.answer', 2)} ]`"></p>
            <div v-if="itemsAreClassified">
                <p class="classifiers-items-list" v-for="attr in attributions" :key="attr.id">
                    <ClassifierItemRow :attr="attr" :state="state"/>
                </p>
            </div>
        </BubbleContent>
    </div>
</template>

<script>
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';
import ClassifierItemRow from '@/components/exams/ClassifierItemRow.vue';

export default {
    props: {
        itemId: {
            default: null
        },
        id: {
            default: null,
        },
        state: { required: true },
    },
    components: {
        BubbleContent,
        ClassifierItemRow,
    },
    data() {
        return {
            fulfilled: true,
            routes: {
                0: "ClassifierExamAnswersContent",
                1: "ClassifiersExamSavedAnswersContent",
                2: "ClassifiersExamToBeReviewdAnswersContent",
                3: "ClassifiersExamToReviewAnswersContent",
            },
        }
    },
    methods: {
        itemsAreClassified() {
            return true
            // if (this.items[this.state].classifications_count[this.state] == 0) {
            //     this.fulfilled == !this.fulfilled
            // }
        },
    },
    computed: {
        attributions() {
            return this.$store.getters['exams/attributions/exam'](this.id)
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        filterItemsByAttribution() {
            const itemAttribution = this.items.filter((item) => item.classifications_number !== 0)
            return itemAttribution
        },
        items() {
            return this.$store.state.exams.items.all
        },
    },
    mounted() {
        this.$store.dispatch('exams/getAll')
        this.$store.dispatch('exams/items/getAll', this.id)
        if (this.exam == null) {
            this.$store.dispatch('exams/getId', this.id)
        }

    },

}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.classifiers-items-container {
    margin: 20px;

    .classifiers-items-title {
        border-bottom: 2px solid $primary-color;
        margin: 0;
        padding: 1rem 0;

    }

    .classifiers-items-list {
        border-top: 1px solid gray;
        margin: 0;


        &:hover {
            background: rgba($primary-color, .1);
        }
    }
}
</style>
