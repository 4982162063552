<template>
    <div class="tp-login-container">
        <router-link class="container-scoi" to="/home">
            {{ vTextScoi }}
            <small v-text="`${$t('generic.scoiVersion')}`"></small>
        </router-link>
        <div class="container-links">
            <input class="hidden-input" type="text" v-if="show">
            <div class="links-anchor" @click="toggleInput">
                <IconComponent class="material-icons toggle-search" icon="search" />
            </div>
            <router-link class="links-anchor" to="#">
                <IconComponent class="material-icons" icon="notification" />
            </router-link>
            <router-link class="links-anchor" to="#">
                <IconComponent class="material-icons" icon="chat" />
            </router-link>
            <div class="logout-anchor" v-if="enterWithNoEmail" @click.prevent="$store.dispatch('logout')">
                <span v-text="`${$t('action.exit')}`"></span>
                <IconComponent class="material-icons logout" icon="logout" />
            </div>
            <div class="dropdown" v-else>
                <button class="dropbtn" v-if="currentUser">{{ currentUser.name }}</button>
                <div class="dropdown-content">
                    <router-link to='/perfil'>
                        <span class="dropdown-option" v-text="`${$t('generic.perfil')}`"></span>
                    </router-link>
                    <router-link to='#' @click.prevent="$store.dispatch('logout')">
                        <span class="dropdown-option exit" v-text="`${$t('action.decisive.exit')}`"></span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import IconComponent from '@/components/IconComponent.vue'

export default {
    components: {
        IconComponent,
    },
    props: {
    },
    data() {
        return {
            show: false,
            vTextScoi: `${this.$t('generic.scoi')}`,
        }
    },
    methods: {
        toggleInput() {
            this.show = !this.show
        },
    },
    computed: {
        currentUser() {
            return this.$store.state.users.currentUser
        },
        enterWithNoEmail(){
            return this.$store.state.users.currentUser.email == ''
        },

    },
    mounted() {
        this.$store.dispatch("getUserFromLocalStorage")
    }

}
</script>
<style lang="scss" scoped>
@import '../../assets/utilities/_variables.scss';

.tp {

    &-login-container {
        @media print {
            display: none;

        }

        .container-scoi {
            color: $primary-color;
            text-decoration: inherit;
        }

        .container-links {
            color: $primary-color;
            display: flex;
            justify-content: space-between;
            height: 100%;

            .material-icons {
                width: 20px;
                fill: $primary-color;
            }

        }

        background: $topbar-bg-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;

        // FIXED LAYOUT
        position: relative;
        height: $topbar-height;
        z-index: 1050;


        // SHADOW
        &::after {
            content: '';
            position: absolute;
            // bottom: $topbar-shadow-size;
            bottom: -$topbar-shadow-size;
            left: 0;
            right: 0;
            height: $topbar-shadow-size;
            background-image: linear-gradient(180deg, $topbar-shadow-color, rgba($topbar-shadow-color, 0));
        }

        input {
            color: $black;
            margin: 0;
            width: 100px;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            border: none;
            font-size: 100%;
            margin: 0;
            border-bottom: 3px solid $primary-color;
        }



        .links-anchor {
            color: inherit;
            text-decoration: inherit;
            display: flex;
            align-items: center;
            padding: 0 10px;
            background-color: rgba(0, 0, 0, 0);
            transition-property: color, background-color;
            padding: 0 20px;


            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
                cursor: pointer;
            }
        }

        .logout-anchor {
            display: flex;
            align-items: center;
            padding: 10px;

            span {
                opacity: 0;
            }


            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
                cursor: pointer;

                span {
                    opacity: 100%;
                    color: $red;

                }
            }

            .logout {
                margin-left: 10px;
                fill: $red;

            }

        }

        .dropbtn {
            background-color: $white;
            color: white;
            padding: 13px;
            font-size: 16px;
            border: none;
            color: $primary-color;
            width: 100px;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
        }


        .dropdown {
            position: relative;
            display: inline-block;
        }

        .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f1f1f1;
            min-width: 100px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;

            .dropdown-option {
                color: $primary-color;
            }

            .exit{
                color:$red;
            }
        }

        .dropdown-content a {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
        }

        .dropdown-content a:hover {
            background-color: rgba(0, 0, 0, 0.1);

        }

        .dropdown:hover .dropdown-content {
            display: block;
        }

        .dropdown:hover .dropbtn {
            background-color: rgba(0, 0, 0, 0.1);
        }


    }

}
</style>
