<template>
    <div class="content-documents-edit">
        <BubbleContent :title="`${$t('action.decisive.change')}`" :buttons=buttons @submit="$store.dispatch('documents/edit', {id, document: {name}})">
            <template #form>
                <InputContent :label="`${$t('generic.name')}`" v-model="name" />
            </template>
        </BubbleContent>
    </div>
</template>
<script>
import BubbleContent from '../BubbleContent/BubbleContent.vue';
import InputContent from '../Inputs/InputContent.vue';


export default {
    components: {
        BubbleContent,
        InputContent,
    },
    props: {
        id: {
            required: true
        }
    },
    data() {
        return {
            name: "",
            buttons: [
                { text: this.$t('action.decisive.edit'), type: 'submit' },
            ],
        };
    },
    computed: {
        newDocumentData() {
            return {
                name: this.name,
            }
        },
        document(){
            return this.$store.getters['documents/getId'](this.id)
        },
    },
    watch: {
        document: {
            immediate: true,
            handler(){
                if(this.document != null){
                    this.name = this.document.name
                }
            },
        }
    },
    mounted() {
        if(this.document == null) {
            this.$store.dispatch('documents/getId', this.id)
        }
    },
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.content-schools-edit {
    margin: 20px;
    color: $black;

    :deep .field {
        margin-bottom: 1.5em;

        input {
            border-radius: 0;
            background-color: $white;
            border: none;
            border-bottom: 4px solid $secondary-color;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            overflow: visible;
            font-family: sans-serif;
            font-size: 100%;
            line-height: 1.15;
            margin: 0;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }
        }

        #role {
            color: $black;
            margin: 0;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            border: none;
            font-size: 100%;
            margin: 0;
            border-bottom: 4px solid $secondary-color;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }


        }
    }
}
</style>