import ExamsView from "../views/ExamsView.vue";
import ClassifierItems from "@/components/exams/ClassifierItems.vue";
import ClassifierExamAnswersContent from "@/components/exams/AnswerCard.vue";

import ExamView from "@/views/exams/ExamView.vue";

import AnswersView from '@/views/exams/AnswersView.vue';
import ClassifiersView from '@/views/exams/ClassifiersView.vue';
import CreateView from "@/views/exams/CreateView.vue";
import DistributeView from '@/views/exams/DistributeView.vue';
import EditView from "@/views/exams/EditView.vue";
import IndexView from "@/views/exams/IndexView.vue";
import ItemsView from "@/views/exams/ItemsView.vue";
import ShowView from "@/views/exams/ShowView.vue";
import TeamsView from "@/views/exams/TeamsView.vue";

import ExamItemEdit from '@/components/SCOI/TabExams/ExamItems/ExamItemEdit.vue'

const exams = [
    {
        path: "/exams",
        component: ExamsView,
        props: true,
        children: [
            {
                path: "",
                name: "ExamsIndex",
                component: IndexView,
                props: true,
                // meta: { adminLogin: true, redirect: "ClassifiersExamsList" },
            },
            {
                path: ":id",
                component: ExamView,
                props: true,
                children: [
                    {
                        path: "",
                        name: "Exam",
                        component: ShowView,
                        props: true,
                    },
                    {
                        path: "classificadores",
                        name: "ExamClassifiers",
                        component: ClassifiersView,
                        props: true,
                    },
                    {
                        path: "distribution",
                        name: "ExamDistribute",
                        component: DistributeView,
                        props: true,
                    },
                    {
                        path: "edit",
                        name: "ExamEdit",
                        component: EditView,
                        props: true,
                    },
                    {
                        path: "items",
                        name: "ExamItems",
                        component: ItemsView,
                        props: true,
                    },
                    {
                        path: "items/:itemId",
                        name: "ExamItemEdit",
                        component: ExamItemEdit,
                        props: true,
                    },
                    {
                        path: "answers",
                        name: "ExamAnswers",
                        component: AnswersView,
                        props: true,
                    },
                    {
                        path: "teams",
                        name: "ExamTeams",
                        component: TeamsView,
                        props: true,
                    },
                ],
            },
            {
                path: "create",
                name: "ExamCreate",
                component: CreateView,
            },
            {
                path: ":id/classificacoes/:itemId",
                name: "ClassifierExamAnswersContent",
                component: ClassifierExamAnswersContent,
                props: route => ({
                    id: parseInt(route.params.id),
                    itemId: parseInt(route.params.itemId),
                    state: 0,
                })
            },
            {
                path: ":id/classificacoes/gravadas",
                name: "ClassifiersExamSaved",
                component: ClassifierItems,
                props: route => ({
                    id: parseInt(route.params.id),
                    state: 1,
                })
            },
            {
                path: ":id/classificacoes/gravadas/:itemId",
                name: "ClassifiersExamSavedAnswersContent",
                component: ClassifierExamAnswersContent,
                props: route => ({
                    id: parseInt(route.params.id),
                    itemId: parseInt(route.params.itemId),
                    state: 1,
                })
            },
            {
                path: ":id/classificacoes/paraRevisao",
                name: "ClassifiersExamToBeReviewd",
                component: ClassifierItems,
                props: route => ({
                    id: parseInt(route.params.id),
                    state: 2,
                })
            },
            {
                path: ":id/classificacoes/paraRevisao/:itemId",
                name: "ClassifiersExamToBeReviewdAnswersContent",
                component: ClassifierExamAnswersContent,
                props: route => ({
                    id: parseInt(route.params.id),
                    itemId: parseInt(route.params.itemId),
                    state: 2,
                })
            },
            {
                path: ":id/classificacoes/revisao",
                name: "ClassifiersExamToReview",
                component: ClassifierItems,
                props: route => ({
                    id: parseInt(route.params.id),
                    state: 3,
                })
            },
            {
                path: ":id/classificacoes/revisao/:itemId",
                name: "ClassifiersExamToReviewAnswersContent",
                component: ClassifierExamAnswersContent,
                props: route => ({
                    id: parseInt(route.params.id),
                    itemId: parseInt(route.params.itemId),
                    state: 3,
                })
            },
        ],
    },
];

export default exams;
