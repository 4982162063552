import Echo from "laravel-echo"
import socketIO from 'socket.io-client';

const options = {
  broadcaster: "socket.io",
  host: `${process.env.VUE_APP_WEBSOCKET_HOST}`,
  client: socketIO,
}

const theEcho = {
    echo: null,
    create(token) {
        this.echo = new Echo({
            ...options,
            auth: {
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
        });
    },
}

export default theEcho;
