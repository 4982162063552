import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../views/LoginPage.vue";
import ResetPassPage from "../views/ResetPassPage.vue";
import HomePage from "../views/HomePage.vue";
import PerfilPage from "../views/PerfilPage.vue";
import PerfilData from "../components/SCOI/TabPerfil/PerfilData.vue";
import users from "./users";
import schools from "./schools";
import exams from "./exams";
import students from "./students";
import documents from "./documents";
import messages from "./messages";
import history from "./history";
import examInfo from "./examInfo";
import settings from "./settings";

const routes = [
  {
    path: "/",
    component: HomePage,
    name: "home",
  },
  {
    path: "/entrar",
    name: "entrar",
    component: LoginPage,
    props: (route) => ({
      token: route.query.token,
    }),
  },
  {
    path: "/senha-reset",
    name: "senha-reset",
    component: ResetPassPage,
  },
  {
    path: "/home",
    component: HomePage,
  },
  {
    path: "/perfil",
    name: "PerfilPage",
    component: PerfilPage,
    children: [
      {
        path: "",
        name: "PerfilData",
        component: PerfilData,
        props: true,
      },
    ],
  },
  ...examInfo,
  ...users,
  ...schools,
  ...exams,
  ...students,
  ...documents,
    ...messages,
  ...history,
  ...settings,
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

import store from "../store";

router.beforeEach((to) => {
  if( to.query.token && store.state.users.currentUser != null ) {
    store.dispatch('logout')
    return {name: 'entrar', query: to.query}
  }

  if( store.state.users.currentUser == null && to.path != '/entrar' ) {
    const goBackTo = {params: to.params, path: to.path}
    store.commit('setAfterLoginRoute', goBackTo)
    return {name: 'entrar', query: to.query}
  }
  if (store.state.users.currentUser == null) {
      return true;
  }
  const isAdmin = store.state.users.currentUser.role == 1;
  if (to.meta.adminLogin && !isAdmin) {
    return { name: to.meta.redirect };
  }
});

export default router;
