<template>
    <div class="exam" v-if="isAdmin">
        <ExamInfoClassification v-if="exam" :id="id"/>
        <TableCard
            :columns="columns"
            :rows="rows"
        />
        <ExamInfoSupervisorClassifiers v-for="group in groups" :key="group.id" :id="group.id"/>
    </div>
    <div class="exam" v-else>
    </div>
</template>

<script>
import ExamInfoClassification from '@/components/exams/GeneralInfo.vue';
import ExamInfoSupervisorClassifiers from '@/components/SCOI/TabExams/ExamInfo/ExamInfoSupervisorClassifiers.vue';

import TableCard from '@/components/TableCard.vue';

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        ExamInfoClassification,
        ExamInfoSupervisorClassifiers,
        TableCard,
    },
    computed: {
        classifiers() {
            return this.$store.getters['exams/classifiers/exam'](this.id)
        },
        columns() {
            let cols = [{ text: this.$tc('models.classifier', 2), class: "left" }]
            for( let item of this.items ) {
                cols.push( {
                    text: item.name,
                    class: 'fit nowrap center',
                })
            }
            cols.push({ text: this.$t('generic.classifiedTotal'), class: 'center fit nowrap' })
            return cols
        },
        currentUser() {
            return this.$store.state.users.currentUser
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        groups() {
            return this.$store.getters['exams/groups/exam'](this.id)
        },
        isAdmin() {
            return this.currentUser != null && this.currentUser.role == 1
        },
        items() {
            return this.$store.getters['exams/items/byExamId'](this.id)
        },
        rows() {
            return this.classifiers.map( (classifier) => {
                let row = [{id: 'name', text: classifier.nr_classificador}]
                for( let item of this.items ) {
                    let text = ""
                    if( classifier.classified_items[item.id] != null ) {
                        let classified = classifier.classified_items[item.id].done
                        let total = classifier.classified_items[item.id].total
                        text = `${classified} | ${total}`
                    }
                    row.push({id: item.id, text})
                }
                row.push({id: 'total', text: `${classifier.classified} | ${classifier.classifications_total}`})
                return row
            })
        },
        classifiedTotal() {
            return this.classifiers.reduce((total, classifier) => {
                for (let itemId in classifier.classified_items) {
                    if (total[itemId] == null) {
                        total[itemId] = { total: 0, classified: 0 }
                    }
                    total[itemId].classified += classifier.classified_items[itemId].classified
                    total[itemId].total += classifier.classified_items[itemId].total
                }
                return total
            }, {})
        },
    },
    mounted() {
        this.$store.dispatch('exams/groups/getAll', this.id)
        this.$store.dispatch('exams/items/getAll', this.id)
        this.$store.dispatch('exams/classifiers/getAll', { examId: this.id })
    }

}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';
.exam {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: $regular-padding;
    overflow: auto;
    max-height: 100%;

    > * {
        flex-shrink: 0;
    }
}
</style>
