import axios from '@/axiosInstance'

const distribution = {
    namespaced: true,
    state: () => ({
        all: [],
        attributions: [], // deprecate this list
        attrs: {},
        attributing: [],
        newAttributions: [],
        loadedAttributions: [],
    }),
    getters: {
        attributions: (state) => (exam) => {
            return state.attributions.filter( ({exam_id}) => exam_id == exam)
        },
        attribution: (state) => (classifierId, itemId) => {
            if( state.attrs[classifierId] == null ) {
                return null
            }
            return state.attrs[classifierId][itemId]
        },
        allAttributions: (state) => (exam_id) => {
            return [
                ...state.attributions.filter( attr => attr.exam_id == exam_id && attr.selected == true ),
                ...state.newAttributions.filter( attr => attr.exam_id == exam_id && attr.selected == true ),
            ]
        },
        newAttribution: (state) => (classifierId, itemId) => {
            return state.newAttributions.find( (attr) => {
                return attr.classifier_id == classifierId && attr.exam_item_id == itemId
            })
        },
        attributing: (state) => (id) => {
            return state.attributing.find((exam) => exam == id) != null
        },
    },
    mutations: {
        addAttributions(state, {attributions, exam, attributing}) {
            let distIndex = state.attributing.findIndex( (id) => exam == id )
            if( attributing && distIndex < 0 ) {
                state.attributing.push(exam)
            } else if( !attributing  && distIndex > -1 ) {
                state.attributing.splice(distIndex, 1)
            }

            state.attributions = state.attributions.filter( ({exam_id}) => exam_id != exam)
            state.attrs = {}
            for( let attr of attributions ) {
                attr.exam_id = exam
                attr.selected = true
                if( state.attrs[attr.classifier_id] == null ) {
                    state.attrs[attr.classifier_id] = {}
                }
                state.attrs[attr.classifier_id][attr.exam_item_id] = attr
                state.attributions.push(attr)
            }
        },
        classification(state, classification){
            const index = state.all.findIndex(({id}) => classification.id === id)
            if(index < 0) {
                state.all.push(classification)
            } else {
                state.all.splice(index, 1, classification)
            }
        },
        clear(state) {
            state.all = []
            state.attributions = []
        },
        selectNew(state, {classifier_id, exam_item_id, exam_id}) {
            let attribution = state.newAttributions.find( (attr) => {
                return attr.classifier_id == classifier_id && attr.exam_item_id == exam_item_id
            })
            if( attribution == null ) {
                attribution = {exam_id, classifier_id, exam_item_id, selected: false, classifications_number: 0}
                state.newAttributions.push(attribution)
            }
            attribution.selected = !attribution.selected
        },
        loadedAttributions(state, id) {
            if( state.loadedAttributions.includes(id) ) {
                return
            }
            state.loadedAttributions.push(id)
        },
        removeAttribution(state, id) {
            let index = state.attributions.findIndex( (attr) => attr.id == id )
            if( index < 0 ) {
                return
            }
            state.attributions.splice(index, 1)
        },
        updateAttribution(state, {attribution, exam}) {
            let attrs = state.attrs[attribution.classifier_id] 
            if( attrs == null ) {
                attrs = {}
            }
            attribution.exam_id = exam
            if( attrs[attribution.exam_item_id] == null ) {
                attrs[attribution.exam_item_id] = {}
            }
            attrs[attribution.exam_item_id] = {
                ...attrs[attribution.exam_item_id],
                ...attribution,
            }
            state.attrs[attribution.classifier_id] = attrs
        },
    },
    actions: {
        async attribute( { commit }, { exam, newAttribution, supervisors } ) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_API_HOST}/exams/${exam}/attribute`,
                    {item_attributions: newAttribution, supervisors})
                console.log(res)
                let data = res.data.data
                commit('addAttributions', {exam, attributions: data.attributions, attributing: data.attributing})
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async distribute( { commit }, { exam, attribution } ) {
            try{
                const results = await axios.post(`${process.env.VUE_APP_API_HOST}/exams/${exam}/distribute`, attribution)
                commit('classification', results.data.data)

            } catch(error) {
                console.log('ERROR: ' + error)
            }

        },
        async getAttributions( { commit }, { examId } ) {
            try {
                const res = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/${examId}/attributions`
                )
                commit('addAttributions', {exam: examId, attributions: res.data.data})
                commit('loadedAttributions', examId)
            } catch (e) {
                console.log(e)
            }
        },
        async moveAttribution( { commit }, { exam, classifier1, classifier2, examItem } ) {
            const basePath = `${process.env.VUE_APP_API_HOST}/exams/${exam}/attributions/move`
            const url = `${basePath}/${classifier1}/${classifier2}/${examItem}`
            try {
                const res = await axios.post(url)
                let data = res.data.data
                commit('addAttributions', {exam, attributions: data})
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async setAttribution( { commit }, { exam, classifier, examItem, amount } ) {
            const basePath = `${process.env.VUE_APP_API_HOST}/exams/${exam}/attributions`
            const url = `${basePath}/${classifier}/${examItem}`
            try {
                const res = await axios.post(url, { number: amount } )
                let data = res.data.data
                commit('updateAttribution', {exam, attribution: data})
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async swapAttribution( { commit }, { exam, classifier1, classifier2, examItem } ) {
            const url = `${process.env.VUE_APP_API_HOST}/exams/${exam}/attributions/${classifier1}/${classifier2}/${examItem}`
            try {
                const res = await axios.post(url)
                let data = res.data.data
                for(let attr of data) {
                    attr.selected = true
                    if( attr.deleted ) {
                        attr.selected = false
                    }
                    commit('updateAttribution', {exam, attribution: attr})
                }
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async redistributeAttribution( { commit }, { exam, classifier, examItem } ) {
            const url = `${process.env.VUE_APP_API_HOST}/exams/${exam}/attributions/redistribute/`
                + `${classifier}/${examItem}`
            try {
                const res = await axios.post(url)
                let data = res.data.data
                commit('removeAttribution', data.id)
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
    },
};

export default distribution;
