<template>
    <div class="file-field">
        <label class="file-label" :for="id">
            {{ label }}
            <template v-if="filename">
                : {{ filename }}
            </template>
        </label>
        <input
            class="file-input" 
            :id="id" 
            :type="type"
            v-model="inputData" 
            @input="input = $event.target.files" 
            :multiple="multiple" 
        />
    </div>
</template>

<script>
    import uuid4 from 'uuid4'

    export default {
        props: {
            label: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                default: 'file',
            },
            modelValue: {
                default: '',
            },
            field: {
                default: '',
            },
            multiple: {
                default: false,
            },
        },
        emits: [
            'update:modelValue',
        ],
        data() {
            return {
                id: uuid4(),
                input: [],
                removeFile: null,
                inputData: "",
            }
        },
        methods: {
        },
        computed: {
            filename() {
                if( this.input.length == 0 ) {
                    return null
                }
                if( this.input.length == 1 ) {
                    return this.input[0].name
                } 
                return `${this.input.length} ficheiros a carregar`
            },
            nrOfFiles() {
                return this.input.length
            },
        },
        watch: {
            input() {
                this.$emit('update:modelValue', this.input)
                // this.inputData = ""
            },
            modelValue() {
                this.input = this.modelValue
            },

        },
        mounted() {
            let r = (Math.random()).toString().replaceAll("0.", "")
            this.id = r
        }
    }
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.file-field {
    margin-bottom: 1.5em;
    margin-top: 20px;

    .file-label{
        background-color: rgba($black, 0);
        color: $primary-color;
        display: inline-block;
        width: 100%;

        cursor: pointer;
        padding: 0.4em;
        transition: .2s ease-out;
        transition-property: background-color, color, border-color;
        border: 1px solid $primary-color;

        &:hover{
            background-color: rgba($primary-color, 1);
            color: $white;

        }
    }
    .file-input{
        display: none;
    }

}
</style>
