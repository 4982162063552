<template>
    <LoginBody>
        <FormResetPass :buttont="$t('action.shaping.recoverPassword')" />
    </LoginBody>
</template>
<script>
import LoginBody from '@/components/LoginBody/LoginBody.vue';
import FormResetPass from '@/components/LoginBody/FormResetPass/FormResetPass.vue';

export default {
    components: {
        LoginBody,
        FormResetPass,
    }

}
</script>
<style lang="scss" scoped></style>