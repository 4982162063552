import axios from '../axiosInstance'

const documents = {
    namespaced: true,
    state: () => ({
        all: [],
        document: null,
        creating: false,
        created: null,
    }),
    getters: {
        getId: (state) => (id) => {
            return state.all.find((document) => document.id === parseInt(id));
        },
    },
    mutations: {
        add(state, newDocument) {
            state.all.push(newDocument);
        },
        all(state, documents) {
            state.all = documents;
        },
        created(state, created) {
            state.created = created;
        },
        creating(state, creating) {
            state.creating = creating;
        },
        delete(state, document) {
            const index = state.all.findIndex(({ id }) => document.id === id);
            state.all.splice(index, 1);
        },
        document(state, document) {
            const index = state.all.findIndex(({ id }) => document.id === id);
            if (index < 0) {
                state.all.push(document);
            } else {
                state.all.splice(index, 1, document);
            }
        },
    },
    actions: {
        async create({ commit }, {id, title, file}) {
            commit('creating', true)
            try {
                let formData = new FormData();
                if( id != null ) {
                    formData.append("exam_id", id);
                }
                if( title != '' ) {
                    formData.append("title", title);
                }
                if( file != null ) {
                    formData.append("file", file);
                }
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/documents`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                commit("add", results.data);
                commit("created", results.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
            commit('creating', false)
        },
        async delete({ commit }, id) {
            try {
                const results = await axios.delete(
                    `${process.env.VUE_APP_API_HOST}/documents/${id}`
                );
                commit("delete", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async edit({ commit }, { id, document }) {
            try {
                const results = await axios.put(
                    `${process.env.VUE_APP_API_HOST}/documents/${id}`,
                    document
                );
                commit("document", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async getAll({ commit }) {
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/documents`
                );
                commit("all", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async getId({ getters, dispatch }, id) {
            if (getters.getId(id) == null) {
                dispatch("fetchId", id);
            }
        },
    },
  };
  
  export default documents;
  
