<template>
    <div class="distribution-content">
        <ExamDistributionItems :id="id"/>
        <br>
        <ExamDistributionClassifications :id="id"/>
    </div>
</template>

<script>
import ExamDistributionItems from '@/components/SCOI/TabExams/ExamDistribution/ExamDistributionItems.vue';
import ExamDistributionClassifications from '@/components/SCOI/TabExams/ExamDistribution/ExamDistributionClassifications.vue';

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        ExamDistributionItems,
        ExamDistributionClassifications,
    },
    data() {
        return {
            button: [
                { text: `${this.$t('action.decisive.distribute')}`, type: 'submit' },
            ],
            attributions: {},
        };
    },
    methods: {
        attributeItems(value, groupId) {
            this.attributions[groupId] = value
        },
    },
    computed: {
        attributionData() {
            let attributions = {}
            for (let item of this.items) {
                attributions[item.id] = []
                for (let groupId in this.attributions) {
                    let groupAttributions = this.attributions[groupId]
                    let itemAttributions = groupAttributions[item.id]
                    if (itemAttributions == null) {
                        continue
                    }
                    attributions[item.id] = [
                        ...attributions[item.id],
                        ...itemAttributions
                    ]
                }
            }
            return attributions
        },
        items() {
            return this.$store.state.exams.items.all
        },
    },
    mounted() {
        this.$store.dispatch('exams/items/getAll', this.id)
        this.$store.dispatch('exams/groups/getAll', this.id)
    },

}
</script>
<style lang="scss" scoped>

.distribution-content{
    height: 100%;
}
</style>
    
    
    
    
