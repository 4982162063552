<template>
    <div>
        <TopContainerNav v-if="currentUser" :title="`${$tc('models.user')}: `+ currentUser.name"/>
        <div>
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import TopContainerNav from '@/components/SCOI/TopContainerNav/TopContainerNav.vue';

export default {
    props: {},
    components: {
        TopContainerNav,
    },
    data() {
        return {};
    },
    computed: {
        currentUser() {
            return this.$store.state.users.currentUser
        },
    },
}
</script>
<style lang="scss" scoped></style>
