<template>
    <router-link :to="{ name: routes[state], params: { itemId: item.id } }" v-if="item" class="item">
        <strong>{{ item.name }} </strong>
        <span v-text="progressText"></span>
    </router-link>
</template>

<script>
// const states = {
//     toclassify: 0,
//     saved: 1,
//     toreview: 2,
//     classified: 3,
//     iave: 4,
// }

export default {
    props: {
        state: {default: 0},
        attr: {required: true},
    },
    data() {
        return {
            routes: {
                0: "ClassifierExamAnswersContent",
                1: "ClassifiersExamSavedAnswersContent",
                2: "ClassifiersExamToBeReviewdAnswersContent",
                3: "ClassifiersExamToReviewAnswersContent",
            },
        }
    },
    computed: {
        classifier() {
            if( this.item == null ) {
                return null
            }
            return this.$store.getters['exams/classifiers/examFirst'](this.item.exam_id)
        },
        item() {
            return this.$store.getters['exams/items/getId'](this.attr.exam_item_id)
        },
        progressText() {
            if( this.item == null ) {
                return "...."
            }
            if( this.classifier == null ) {
                return "...."
            }
            let text =` [`
            let classifiedItem = this.classifier.classified_items[this.item.id]
            if( this.state == 0 && classifiedItem != null ) {
                let classified = classifiedItem.done
                classified += classifiedItem.review
                classified += classifiedItem.saved
                text += `${classified} / ${ this.attr.classifications_number}]`
            }
            if( this.state == 1 && classifiedItem != null ) {
                text += classifiedItem.saved
                text += `]`
            }
            if( this.state == 2 && classifiedItem != null ) {
                text += classifiedItem.review
                text += `]`
            }
            if( this.state == 3 && classifiedItem != null ) {
                text += classifiedItem.done
                text += `]`
            }
            return text
        },
    },
}
</script>

<style scoped>
.item {
    width: 100%;
    display: block;
    padding: 1rem;
}
</style>
