const EMPTY_NAME = {
    id: "",
    classifier: "",
    value_default: 'default',
    value: "",
    created_at: "",
    updated_at: "",
  }
// console.log(EMPTY_NAME)

const nomenclature = {
  namespaced: true,
  state: {
    name: '',
  },
  getters: {},
  mutations: {
    UPDATE_NAME(state, value) {
      state.name = value
    },
  },
  actions: {
    updateName({commit}, name) {
      const emptyName = {...EMPTY_NAME, name}

        console.log(emptyName)
        commit('UPDATE_NAME', emptyName)
    },
  },
};

export default nomenclature;
