<template>
    <div class="documents-container">
        <TableCard :columns="columns" :rows="rows" class="table" />
    </div>
</template>

<script>
    import TableCard from '@/components/TableCard.vue';

    export default {
        components: {
            TableCard,
        },
        data() {
            return {
                columns: [
                    { text: `${this.$tc('models.document')}` },
                    { text: `${this.$tc('models.exam')}` },
                    { text: `` },
                ],
            };
        },
        computed: {
            documents() {
                return this.$store.state.documents.all
            },
            rows() {
                return this.documents.map( (doc) =>  {
                    let exam = this.$store.getters['exams/getId'](doc.exam_id)
                    if( exam == null ) {
                        return [{text: doc.title},{text: "..."},{icon: 'trash'}]
                    }
                    return [
                        {text: doc.title, url: doc.link },
                        {text: this.$store.getters['exams/getId'](doc.exam_id).name, url: doc.link, class: "center"},
                        {icon: "trash", class: 'right', action: () => this.$store.dispatch("documents/delete", doc.id) },
                    ]
                })
            },
        },
        mounted() {
            this.$store.dispatch("exams/getAll");
            this.$store.dispatch("documents/getAll");
        },
    }
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.documents-container {
    margin: 20px;
}
</style>

