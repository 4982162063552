<template>
    <BubbleContent :title="`${$t('action.shaping.itemToClassify', { itemName: exam.name })}`">
        <template #form>
            <form>
                <p v-for="line, index in list" :key="index">
                    <strong>{{ line.name }}: </strong>
                    <span>{{ line.answer }}</span>
                </p>
                <table>
                    <thead>
                        <tr class="info-classification-columns">
                            <th v-text="`${$tc('models.answer')} ...`"></th>
                            <th v-text="`${$t('generic.total')}`"></th>
                            <th v-for="item in items" :key="item.id" v-text="item.name">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="classification in classifications" :key="classification.answer">
                            <td>{{ classification.answer }}</td>
                        </tr>
                    </tbody>
                </table>
                <span>
                    <a href="#" class="double-classification-link"
                        @click.prevent="requestCalculation"
                        v-text="`${$t('action.shaping.updateDoubleClassificationInfo')}` + ' ... ( Em Progresso )'">
                    </a>
                </span>
            </form>
        </template>
    </BubbleContent>
</template>

<script>
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';
import api from "@/api"

export default {
    props: {
        id: {
            required: true,
        },
    },
    components: {
        BubbleContent,
    },
    data() {
        return {
            submissions: null,
            classifications: [
                { answer: `${this.$t('action.shaping.doubleClassified')}` },
                { answer: `${this.$t('action.shaping.tripleClassified')}` },
                { answer: `${this.$t('action.shaping.classifiedAfterConflict')}` },
            ]
        }
    },
    methods: {
        getClassifiers() {
            this.$store.dispatch('exams/classifiers/getAll', { examId: this.id })
        },
        requestCalculation() {
            api.exams.classifications.updateAll(this.id);
        },
    },
    computed: {
        classifiers() {
            return this.$store.getters['exams/classifiers/exam'](this.id)
        },
        totalItemsClassified() {
            const arrayClassification = this.classifiers.reduce((total, classifier) => {
                for (let itemId in classifier.classified_items) {
                    if (total[itemId] == null) {
                        total[itemId] = { total: 0, classified: 0 }
                    }
                    total[itemId].classified += classifier.classified_items[itemId].classified
                    total[itemId].total += classifier.classified_items[itemId].total
                }
                return total
            }, {})
            return arrayClassification
        },
        classifiedTotal() {
            const arrayClassification = this.classifiers.map((classifier) => classifier.classified)
            const initialValue = 0
            const sumClassification = arrayClassification.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue)
            return sumClassification
        },
        classificationTotal() {
            const arrayClassification = this.classifiers.map((classifier) => classifier.classifications_total)
            const initialValue = 0
            const sumClassification = arrayClassification.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue)
            return sumClassification
        },
        doubleClassification() {
            const arrayClassification = this.classifiers.map((classifier) => classifier.statistic_double_classifications)
            const initialValue = 0
            const sumClassification = arrayClassification.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue)
            return sumClassification
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        items() {
            return this.$store.state.exams.items.all
        },
        list() {
            return [
                { name: `${this.$t('generic.name')}`, answer: this.exam.name },
                { name: `${this.$t('generic.table')}`, answer: this.exam.table },
                { name: `${this.$t('generic.type')}`, answer: this.exam.kind },
                { name: `${this.$t('generic.progress')}`, answer: 'Em Progresso' }, //! GET THE RIGHT ANSWER
                { name: `${this.$tc('models.answer')}`, answer: 'Em Progresso' }, //! GET THE RIGHT ANSWER
                { name: `${this.$t('generic.classified_f')}`, answer: this.classifiedTotal },
                { name: `${this.$tc('models.submission', 2)}`, answer: this.exam.submissions },
            ]
        },
    },
    mounted() {
        this.$store.dispatch('exams/items/getAll', this.id)

        if (this.exam == null) {
            this.$store.dispatch('exams/getId', this.id)
        }
        this.getClassifiers()
    },
}

</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.checkLink {
    color: $primary-color;
    margin: 0 0 0 .7em;
}

.info-classification-list {
    border-top: 1px solid rgba($black, .5);
}

table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    td,
    th {
        padding: .5em 0;
    }

    .info-classification-columns {
        border-bottom: 2px solid $primary-color;
        color: rgba($black, .5);
    }
}

.double-classification-link {
    text-decoration: none;
    color: $primary-color;
    cursor: pointer;
}
</style>
