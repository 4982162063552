<template>
    <div class="index">
        <TableCard :columns=columns :rows="examRows">
            <DeletePopup 
                v-show="popupVisible == exam.id" 
                :key="exam.id" @cancel="closePopup" 
                @execute="deleteObj(exam)"
                v-for="exam in exams" />
        </TableCard>
    </div>
</template>
<script>

import TableCard from '@/components/TableCard.vue';
import DeletePopup from '@/components/SCOI/DeletePopup/DeletePopup.vue';

export default {
    components: {
        TableCard,
        DeletePopup,
    },
    props: {},
    data() {
        return {
            columns: [
                { text: `${this.$t('generic.name')}`, class: "left" },
                { text: `${this.$t('generic.startAt')}`, class: "center alert" },
                { text: `${this.$t('generic.endAt')}`, class: "center" },
                { text: `${this.$t('generic.conclusion')}`, sortable: false },
            ],
            popupVisible: null,
        };
    },
    methods: {
        closePopup() {
            this.popupVisible = null;
        },
        deleteObj(exam) {
            this.$store.dispatch('exams/delete', exam.id)
        },
        showPopup(id) {
            this.popupVisible = id;
        },
    },
    computed: {
        currentUser() {
            return this.$store.state.users.currentUser
        },
        exams() {
            return this.$store.state.exams.all;
        },
        examRows() {
            return this.exams.map((exam) => {
                let row = [
                    { id: 'name', text: exam.name, link: { name: 'Exam', params: { id: exam.id } } },
                    { id: 'starts_at', text: exam.starts_at, link: { name: 'Exam', params: { id: exam.id } } },
                    { id: 'ends_at', text: exam.ends_at, link: { name: 'Exam', params: { id: exam.id } } },
                    { id: 'conclusion', text: exam.statistic_conclusion, link: { name: 'Exam', params: { id: exam.id } } },
                ]
                if( this.isAdmin ) {
                    row.push(
                        { id: 'remove', text: this.$t('action.decisive.remove'), action: () => { this.showPopup(exam.id) } }
                    )
                }
                return row
            })
        },
        isAdmin() {
            return this.currentUser != null && this.currentUser.role == 1
        },
    },
    watch: {
        currentUser: {
            immediate: true,
            handler() {
                if( this.isAdmin && this.columns.length < 5 ) {
                    this.columns.push(
                        { text: '', class: "alert right fit", sortable: false }
                    )
                }
            },
        },
    },
    mounted() {
        this.$store.dispatch('exams/getAll')
    },

}
</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.index {
    display: flex;
    padding: $regular-padding;
}
</style>
