<template>
    <div class="loader" v-if="info == null || ! info.done" v-text="message"></div>
    <div class="error" v-else-if="info.error">
        {{ info.message }}
        <IconComponent icon="crossV2" class="error-icon" />
    </div>
    <div class="success" v-else>
        {{ info.message }}
        <IconComponent icon="correct" class="success-icon" />
    </div>
</template>
<script>
import IconComponent from '@/components/IconComponent.vue';
export default {
    props: {
        info: {
            default: null,
        }
    },
    components: {
        IconComponent
    },
    computed: {
        message() {
            if( this.info == null || this.info.message == null ) {
                return this.$t('action.shaping.processing')
            }
            return this.info.message
        }
    },
}

</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

@keyframes loading {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader {
    background: rgba($primary-color, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.75s, visibility 0.75s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    border-radius: 0 0 10px 10px;
    color: $primary-color;
    font-size: larger;
    font-weight: bold;


}



.loader--hidden {
    opacity: 0;
    visibility: hidden;
}

.loader::after {
    content: "";
    width: 50px;
    height: 50px;
    border: 15px solid $white;
    border-top-color: $primary-color;
    border-radius: 50%;
    animation: loading 0.75s linear infinite;
}



.error {
    background: rgba($red, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.75s, visibility 0.75s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    border-radius: 0 0 10px 10px;
    color: $red;
    font-size: larger;
    font-weight: bold;

}

.success--hidden {
    opacity: 0;
    visibility: hidden;
}

.error-icon {
    width: 80px;
    fill: $red;
}
.success {
    background: rgba($green, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.75s, visibility 0.75s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    border-radius: 0 0 10px 10px;
    color: $green;
    font-size: larger;
    font-weight: bold;

}

.success--hidden {
    opacity: 0;
    visibility: hidden;
}

.success-icon {
    width: 90px;
    fill: $green;
}

</style>
