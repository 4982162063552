import DocumentsPage from "../views/DocumentsPage.vue";
import DocumentsList from "../components/SCOI/TabDocuments/DocumentsList.vue";
import DocumentEdit from "../components/SCOI/TabDocuments/DocumentEdit.vue";
import DocumentCreate from "@/views/DocumentsCreate.vue";

const documents = [
  {
    path: "/documents",
    component: DocumentsPage,
    children: [
      {
        path: "",
        name:'DocumentsList',
        component: DocumentsList,
      },
      {
        path: ":id",
        name: "DocumentEdit",
        component: DocumentEdit,
        props: true,
      },
      {
        path: "create",
        name: "DocumentCreate",
        component: DocumentCreate,
      },
    ],
  },
];

export default documents;
