<template>
    <div class="exams">
        <TopContainerNav :title="title"/>
        <TopContainerNavBody :actions=actions />
        <router-view class="view"></router-view>
    </div>
</template>

<script>
    import TopContainerNav from '@/components/SCOI/TopContainerNav/TopContainerNav.vue';
    import TopContainerNavBody from '@/components/SCOI/TopContainerNav/TopContainerNavBody.vue';
    export default {
        props: {
            id: {
                default: null,
            },
            state: {
                default: null
            },
        },
        components: {
            TopContainerNav,
            TopContainerNavBody,
        },
        data() {
            return {
            }
        },
        computed: {
            actions() {
                if( this.isAdmin ) {
                    return [
                        ...this.regularActions,
                        ...this.classifierActions,
                        ...this.adminActions,
                    ]
                }
                return [
                    ...this.regularActions,
                    ...this.classifierActions,
                ]
            },
            adminActions() {
                if( !this.isAdmin ) {
                    return []
                }
                if( this.id == null ) {
                    return [
                        { text: this.$t('action.decisive.create'), route: '/exams/create', },
                    ]
                }
                return [
                    {
                        text: this.$t('generic.information'),
                        route: { name: "Exam", params: { id: this.id } }
                    },
                    // {
                    //     text: this.$t('action.decisive.distribute'),
                    //     route: { name: "ExamDistribute", params: { id: this.id } }
                    // },
                    {
                        text: this.$tc('models.classifier', 2),
                        route: { name: "ExamClassifiers", params: { id: this.id } }
                    },
                    // { text: this.$tc('generic.group'), route: { name: "ExamGroups", params: { id: this.id } } },
                    {
                        text: this.$tc('models.item', 2),
                        route: { name: "ExamItems", params: { id: this.id } }
                    },
                    // { text: this.$tc('models.answer'), route: { name: "ExamAnswers", params: { id: this.id } } },
                    // { text: this.$t('generic.statistic'), route: { name: "ExamStatistics", params: { id: this.id } } },
                    {
                        text: this.$tc('generic.team', 2),
                        route: { name: "ExamTeams", params: { id: this.id } }
                    },
                    {
                        text: this.$t('action.decisive.edit'),
                        route: { name: "ExamEdit", params: { id: this.id } }
                    },
                    {
                        text: this.$tc('models.answer', 2),
                        route: { name: "ExamAnswers", params: { id: this.id } }
                    },
                ]
            },
            attributions() {
                if( this.classifier == null ) {
                    return null
                }
                return this.$store.getters['exams/attributions/classifier'](this.classifier.id)
            },
            classifierActions() {
                if( this.isAdmin || this.id == null ) {
                    return []
                }
                return [
                    {
                        text: `${this.$t('action.decisive.classify')} (${this.countClassify})`,
                        route: { name: "Exam", params: { id: this.id } }
                    },
                    {
                        text: `${this.$t('generic.saved')} (${this.countSaved})`,
                        route: { name: "ClassifiersExamSaved", params: { id: this.id } }
                    },
                    // {
                    //     text: `${this.$t('action.shaping.pointedToReview')} (${this.countToReview})`,
                    //     route: { name: "ClassifiersExamToBeReviewd", params: { id: this.id } }
                    // },
                    {
                        text: `${this.$tc('generic.classified_f', 2)} (${this.countReview})`,
                        route: { name: "ClassifiersExamToReview", params: { id: this.id } }
                    },
                ]
            },
            regularActions() {
                if( this.id != null || this.$route.name == 'ExamCreate' ) {
                    return [
                        {
                            text: this.$tc('models.exam', 2),
                            route: {name: 'ExamsIndex'}
                        },
                    ]
                }
                return []
            },
            title() {
                let title = this.$tc('models.exam', 2)
                if( this.exam != null ) {
                    title += ` > ${this.exam.name}`
                }
                if( this.$route.name == 'ExamCreate' ) {
                    title += ` > ${this.$t('generic.new')}`
                }
                return title
            },
            currentUser() {
                return this.$store.state.users.currentUser
            },
            exam() {
                return this.$store.getters['exams/getId'](this.id)
            },
            isAdmin() {
                return this.currentUser != null && this.currentUser.role == 1
            },
            item() {
                return this.$store.getters['exams/items/getId'](this.itemId)
            },
            items() {
                return this.$store.state.exams.items.all
            },
            classifier() {
                return this.$store.getters['exams/classifiers/examFirst'](this.id)
            },
            // -------------------- GET A WAY TO OPTIMIZE THIS PART OF CODE
            classify() {
                const item = this.items.map(item => item.classifications_count[0])
                const itemFilter = item.filter(i => i !== undefined)
                return itemFilter
            },
            saved() {
                const item = this.items.map(item => item.classifications_count[1])
                const itemFilter = item.filter(i => i !== undefined)
                return itemFilter
            },
            markToReview() {
                const item = this.items.map(item => item.classifications_count[2])
                const itemFilter = item.filter(i => i !== undefined)
                return itemFilter
            },
            countClassify() {
                if( this.attributions == null ) {
                    return ".."
                }
                let classifications_number = this.attributions.reduce(
                    (acc, curr) => acc + curr.classifications_number,
                    0,
                )
                return classifications_number - this.countSaved - this.countToReview - this.countReview
            },
            countReview() {
                if( this.classifier == null ) {
                    return ".."
                }
                if( this.classifier.classified_items == null ) {
                    return ".."
                }
                let count = 0
                for(let itemId in this.classifier.classified_items ) {
                    if( this.classifier.classified_items[itemId]['done'] != null ) {
                        count += this.classifier.classified_items[itemId]['done']
                    }
                }
                return count
            },
            countSaved() {
                if( this.classifier == null ) {
                    return ".."
                }
                if( this.classifier.classified_items == null ) {
                    return ".."
                }
                return Object.values(this.classifier.classified_items).reduce(
                    (acc, curr) => acc + (curr.saved == null ? 0 : curr.saved),
                    0,
                )
            },
            countToReview() {
                if( this.classifier == null ) {
                    return ".."
                }
                if( this.classifier.classified_items == null ) {
                    return ".."
                }
                let count = 0
                count = Object.values(this.classifier.classified_items).reduce(
                    (acc, curr) => acc + (curr.review == null ? 0 : curr.review),
                    0,
                )
                return count
            },
            // -------------------- GET A WAY TO OPTIMIZE THIS PART OF CODE
        },
        watch: {
            currentUser: {
                immediate: true,
                handler() {
                    if( this.currentUser == null ) {
                        return
                    }
                    this.$store.dispatch('exams/classifiers/get', this.currentUser.id)
                }
            },
            id: {
                immediate: true,
                handler() {
                    this.$store.dispatch('exams/attributions/getAll', this.id)
                },
            },
            classifier: {
                immediate: true,
                handler() {
                    if( this.classifier == null ) {
                        return
                    }
                    console.log(this.classifier)
                    this.$store.dispatch('exams/classifiers/listen', this.classifier.id)
                },
            },
        },
        mounted() {
            this.$store.dispatch('exams/getAll')
            if( this.id ) {
                this.$store.dispatch('exams/items/getAll', this.id)
            }
            if (this.exam == null && this.id != null) {
                this.$store.dispatch('exams/getId', this.id)
            }
            this.$store.dispatch('exams/listenAll')
        }
    }
</script>

<style lang="scss" scoped>
.view {
    overflow: auto;
    height: 100%;
}
</style>
