<template>
    <div class="users">
        <TopContainerNav :title="`${$tc('models.user')}`" />
        <TopContainerNavBody :actions="actions" />
        <router-view class="users_view"></router-view>
    </div>
</template>

<script>
import TopContainerNav from '@/components/SCOI/TopContainerNav/TopContainerNav.vue'
import TopContainerNavBody from '@/components/SCOI/TopContainerNav/TopContainerNavBody.vue';

export default {
    components: {
        TopContainerNav,
        TopContainerNavBody,
    },
    data() {
        return {};
    },
    computed: {
        actions() {
            return [
                { text: `${this.$tc('models.user')}`, route: '/users', show: true },
                { text: `${this.$t('action.decisive.add')}`, route: '/users/create', show: this.isAdmin },

            ]
        },
        currentUser() {
            return this.$store.state.users.currentUser
        },
        isAdmin() {
            return this.currentUser != null && this.currentUser.role == 1
        },
    },
}
</script>
<style lang="scss" scoped>
.users {
    &_view {
        flex-grow: 1;
    }
}
</style>
