<template>
    <ContentCard
        class="card"
        :title="`${$t('action.shaping.distributeClassification')}`"
        :buttons="button"
        :loading="loading"
        @submit.prevent="submit">
        <ScrollUpButton />

        <FloatingOptions
            class="settings"
            @mouseleave="closeOptions"
            :show="position"
            :options="options"
        ></FloatingOptions>

        <table>
            <thead>
                <tr>
                    <th class="left number"></th>
                    <th class="classifier_id left" v-text="`${$tc('models.classifier')}`"></th>
                    <th v-for="item in items" :key="item.id" class="item">
                        <span>{{ item.name }}</span>
                        <br>
                        <span>
                            {{ itemAttributions[item.id] }} | {{ (itemAttributions[item.id]*100/exam.submissions).toFixed(1) }}%
                        </span>
                    </th>
                    <th v-text="`${$t('generic.effort')}`" class="effort"></th>
                </tr>
            </thead>
            <tbody v-if="classifiersEfforts">
                <tr v-for="classifier, index in classifiers" :key="classifier.id"
                    :class="{ supervisor: classifier.supervisor }">
                    <td class="right number">
                        <strong v-text="index + 1"></strong>
                    </td>
                    <td class="classifier_id right">
                        <span v-text="classifier.nr_classificador" @click.prevent="upgrade(classifier.id)"></span>
                    </td>
                    <td v-for="item in items" :key="item.id" class="item"
                        :ref="`classifier_${classifier.id}_item_${item.id}`"
                        @click.right.prevent="openOptions($event, classifier.id, item.id)">
                        <DistributionButton
                            :id="`${classifier.id}_${item.id}`"
                            :classifierId="classifier.id"
                            :itemId="item.id"
                            :ref="`button_${classifier.id}_item_${item.id}`"
                            @options="openOptions($event, classifier.id, item.id)"
                            @select="select(classifier.id, item.id, $event)">
                        </DistributionButton>
                    </td>
                    <td v-text="classifiersEfforts[classifier.id]" class="effort right"></td>
                </tr>
            </tbody>
        </table>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard.vue'
import DistributionButton from '@/components/exams/DistributionButton.vue'
import FloatingOptions from '@/components/FloatingOptions'
import ScrollUpButton from '@/components/SCOI/ScrollUpButton/ScrollUpButton.vue'

export default {
    props: {
        id: {
            required: true,
        },
    },
    components: {
        ContentCard,
        DistributionButton,
        FloatingOptions,
        ScrollUpButton,
    },
    data() {
        return {
            button: [
                { text: `${this.$t('action.decisive.distribute')}`, type: 'submit' },
            ],
            lastClick: '',
            classificationsNumber: null,
            activated: {},
            submitted: false,
            position: null,
            activeItem: null,
            activeClassifier: null,
            escapeListener: null,
            activeMethod: null,
        }
    },
    methods: {
        activate(classifier, item) {
            this.activeClassifier = classifier
            this.activeItem = item
        },
        deactivate() {
            if( this.activeButton ) {
                this.activeButton.editMode = false
            }
            this.activated[this.activeRef] = false
            this.activeClassifier = null
            this.activeItem = null
        },
        escape(e) {
            if( e.key != "Escape" ) {
                return
            }
            this.deactivate()
        },
        closeOptions() {
            this.position = null
        },
        openOptions(e, classifier_id, item_id) {
            this.activate(classifier_id, item_id)
            this.position = {
                x: e.x,
                y: e.y,
            }
        },
        moveClassificationsNumber(classifier1_id, classifier2_id, item_id) {
            this.$store.dispatch('exams/distribution/moveAttribution', {
                exam: this.id,
                classifier1: classifier1_id,
                classifier2: classifier2_id,
                examItem: item_id,
            })
        },
        swapClassificationsNumber(classifier1_id, classifier2_id, item_id) {
            this.$store.dispatch('exams/distribution/swapAttribution', {
                exam: this.id,
                classifier1: classifier1_id,
                classifier2: classifier2_id,
                examItem: item_id,
            })
        },
        redistributeClassificationsNumber(classifier_id, item_id) {
            this.$store.dispatch('exams/distribution/redistributeAttribution', {
                exam: this.id,
                classifier: classifier_id,
                examItem: item_id,
            })
        },
        submit() {
            console.log(this.supervisorAttributions)
            let classifierSelect = this.$store.getters['exams/distribution/allAttributions'](this.id)
            console.log(classifierSelect)
            this.$store.dispatch('exams/distribution/attribute', {
                exam: this.id,
                newAttribution: classifierSelect,
                supervisors: this.supervisorAttributions
            })
            this.submitted = true
        },
        upgrade(classifier) {
            this.$store.dispatch('exams/classifiers/upgrade', classifier)
        },
        select(classifier, item, e = null) {
            if( this.activeMethod != null ) {
                return this.activeMethod(classifier, item, e)
            }
            if( e && e.shift && this.lastClick != '' ) {
                const lastClassifier = this.lastClick.classifier
                const lastItem = this.lastClick.item
                const lastClassifierIndex = this.classifiers.findIndex(({ id }) => parseInt(lastClassifier) == id)
                const classifierIndex = this.classifiers.findIndex(({ id }) => parseInt(classifier) == id)
                const minClassifier = Math.min(lastClassifierIndex, classifierIndex)
                const maxClassifier = Math.max(lastClassifierIndex, classifierIndex)
                const classifiers = this.classifiers.slice(minClassifier, maxClassifier + 1)

                const lastItemIndex = this.items.findIndex(({ id }) => parseInt(lastItem) == id)
                const itemIndex = this.items.findIndex(({ id }) => parseInt(item) == id)
                const minItem = Math.min(lastItemIndex, itemIndex)
                const maxItem = Math.max(lastItemIndex, itemIndex)
                const items = this.items.slice(minItem, maxItem + 1)

                for (let clas of classifiers) {
                    for (let it of items) {
                        if( classifier == clas.id && it.id == item) {
                            continue
                        }
                        if (lastClassifier != clas.id || lastItem != it.id) {
                            let ref = this.$refs[`button_${clas.id}_item_${it.id}`][0]
                            ref.selectButton()
                        }
                    }
                }
            }
            this.lastClick = { classifier, item }
        },
        initSelection() {
            this.classificationsNumber = null
            if (this.classifiers.length == 0) {
                return
            }
            let classificationsNumber = {}
            for (let classifier of this.classifiers) {
                let items = {}
                let classifications = {}
                for (let item of this.items) {
                    items[item.id] = false
                    classifications[item.id] = 0
                }
                classificationsNumber[classifier.id] = classifications
            }
            this.classificationsNumber = classificationsNumber
        }
    },
    computed: {
        activeRef() {
            return `classifier_${this.activeClassifier}_item_${this.activeItem}`
        },
        activeButton() {
            if( this.activeClassifier != null && this.activeItem != null ) {
                return this.$refs[`button_${this.activeClassifier}_item_${this.activeItem}`][0]
            }
            return null
        },
        attributing() {
            return this.$store.getters['exams/distribution/attributing'](this.id)
        },
        loading() {
            return this.$store.getters['exams/classifiers/loading'](this.id) || this.attributing
        },
        classifiers() {
            return this.$store.getters['exams/classifiers/exam'](this.id)
        },
        classifiersEfforts() {
            let efforts = {}
            for(let attribution of this.attributions) {
                let item = this.items.find( ({id}) => id == attribution.exam_item_id )
                let effort = efforts[attribution.classifier_id]
                if( ! effort ) {
                    effort = 0
                }
                effort += attribution.classifications_number * item.effort
                efforts[attribution.classifier_id] = effort
            }
            return efforts
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        items() {
            return this.$store.state.exams.items.all
        },
        itemAttributions() {
            let attributions = {}
            for(let attribution of this.attributions) {
                let item = this.items.find( ({id}) => id == attribution.exam_item_id )
                let count = attributions[item.id]
                if( ! count ) {
                    count = 0
                }
                count += parseInt(attribution.classifications_number)
                attributions[item.id] = count
            }
            return attributions
        },
        options() {
            let currentValue = 0
            const els = this.$refs[this.activeRef]
            if( els ) {
                currentValue = parseInt(els[0].innerText)
            }
            return [
                {
                    text: `${this.$t('action.decisive.edit')}`, action: () => {
                        if( this.activeButton ) {
                            this.activeButton.edit()
                        }
                    }
                },
                {
                    text: `${this.$t('action.shaping.attributeOther')}`,
                    disabled: currentValue == 0,
                    action: () => {
                        const el = this.$refs[this.activeRef][0]
                        if( el == null ) {
                            return
                        }
                        el.classList.add("active")
                        this.activeMethod = (classifier, item) => {
                            if( item != this.activeItem ) {
                                return
                            }
                            this.moveClassificationsNumber(this.activeClassifier, classifier, item)
                            el.classList.remove("active")
                            this.activeMethod = null
                        }
                    }
                },
                {
                    text: `${this.$t('action.decisive.switch')}`,
                    action: () => {
                        const el = this.$refs[this.activeRef][0]
                        if( el == null ) {
                            return
                        }
                        el.classList.add("active")
                        this.activeMethod = (classifier, item) => {
                            if( item != this.activeItem ) {
                                return
                            }
                            //const secondNumber = this.getClassificationsNumber(classifier, item)
                            this.swapClassificationsNumber(this.activeClassifier, classifier, this.activeItem)
                            el.classList.remove("active")
                            this.activeMethod = null
                        }
                    },
                },
                {
                    text: `${this.$t('action.decisive.redistribute')}`,
                    action: () => {
                        // this.select(this.activeClassifier, this.activeItem)
                        this.redistributeClassificationsNumber(this.activeClassifier, this.activeItem)
                    },
                },
            ]
        },
        attributions() {
            return this.$store.getters['exams/distribution/attributions'](this.id)
        },
        supervisorAttributions() {
            let supervisors = this.classifiers.filter(({ supervisor }) => supervisor)
            let attributions = {}
            for (let supervisor of supervisors) {
                attributions[supervisor.id] = this.classificationsNumber[supervisor.id]
            }
            if( Object.values(attributions).length == 0 ) {
                return null
            }
            return attributions
        },
    },
    watch: {
        classifiers() {
            this.initSelection()
            this.$store.dispatch('exams/distribution/getAttributions', { examId: this.id })
        },
        items() {
            this.initSelection()
        },
        attributions() {
            this.submitted = false
            for (let att of this.attributions) {
                this.classificationsNumber[att.classifier_id][att.exam_item_id] = att.classifications_number
            }
        },
    },
    mounted() {
        this.$store.dispatch('exams/items/getAll', this.id)
        this.$store.dispatch('exams/classifiers/getAll', { examId: this.id })
        document.addEventListener("keydown", this.escape, true)
    },
    unmounted() {
        document.removeEventListener("keydown", this.escape, true)
    }
}

</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

span {
    &.activated {
        background-color: $white;
    }
}

.card {
    display: flex;
    flex-direction: column;
}

.number, .effort {
    padding: 0 1em;
}

table {
    width: 100%;
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    flex-grow: 1;
    overflow: auto;
    position: relative;

    thead {
        position: sticky;
        top: 0;
        background-color: rgba($white, 1);
        z-index: 10;

        th {
            border-bottom: 1px solid rgba($black, .5);
        }
    }

    td, th {
        white-space: nowrap;
        width: 1%;

        &.number, &.classifier_id, &.effort {
            width: 0%;
        }

        a {
            will-change: background;
        }

        &.item {
            &.active {
                a {
                    background-color: rgba($alert-color, .75);
                }
            }
        }
    }

    td {
        text-align: center;
        padding: .2em;
        cursor: default;
    }

    tbody {
        tr {
            &:nth-child(odd) {
                background-color: rgba($primary-color, .1);
            }

            &.supervisor {
                background-color: rgba($black, .15);

                &:nth-child(odd) {
                    background-color: rgba($black, .2);
                }
            }
        }
    }

    .left {
        text-align: left;
    }

    .right {
        text-align: center;
    }
}


.distribution-items {
    border-bottom: 2px solid $primary-color;
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
}

.classifications-total {
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    border-top: 1px solid $black;
}
</style>
