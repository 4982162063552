<template>
    <div class="content-students-create">
        <BubbleContent :title="`${$t('action.shaping.addStudent')}`" :buttons=buttons @submit="$store.dispatch('student/create', newStudentData)">
            <template #form>
                <InputContent :label="`${$t('generic.name')}`" v-model="name" />
                <InputSelectContent :label="`${$tc('models.school')}`" :options="options" :choose="choose"/>
            </template>
        </BubbleContent>
        <br>
        <UserImport :names="names"/>
    </div>
</template>
<script>
import BubbleContent from '../BubbleContent/BubbleContent.vue';
import UserImport from '../TabUser/UserImport.vue'
import InputContent from '../Inputs/InputContent.vue';
import InputSelectContent from '@/components/input/SelectComponent.vue';

export default {
    components: {
        BubbleContent,
        UserImport,
        InputContent,
        InputSelectContent,
    },
    data() {
        return {
            name: "",
            email: "",
            password: "",
            buttons: [
                { text: `${this.$t('action.decisive.save')}`, type: 'submit' },
            ],
            options: [
                {text: `${this.$tc('models.school')}`, value: '0'},
            ],
            choose: `${this.$t('action.shaping.selectSchool')}`,
            names: [
                { text: `${this.$t('generic.name')}`},
                { text: `${this.$t('generic.code')}`},
            ]
        };
    },
    computed: {
        newStudentData() {
            return {
                name: this.name,
                school: this.school,
            }
        },
    },
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.content-students-create {
    margin: 20px;
    color: $black;

    :deep .field {
        margin-bottom: 1.5em;

        #role {
            color: $black;
            margin: 0;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            border: none;
            font-size: 100%;
            margin: 0;
            border-bottom: 4px solid $secondary-color;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;
            }


        }
    }

}
</style>
