<template>
    <BubbleContent :title="`${$t('action.decisive.supervise')}`">
        <div>
            <div class="info-classifiers-nav">
                <p v-for="column of columns" :key="column.text">
                    {{ column.text }}
                </p>
            </div>
            <div v-for="classifier in classifiers" :key="classifier.id" class="info-list-classifiers">
                <div class="info-classifiers">
                    <p>{{ classifier.name }}</p>
                    <p>Em Progresso!</p>
                    <p>Em Progresso!</p>
                    <p>Em Progresso!</p>
                    <p>{{ classifier.last_logged_in_at }}</p>
                </div>
            </div>
        </div>
    </BubbleContent>
</template>

<script>
import BubbleContent from '../../BubbleContent/BubbleContent.vue';

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        BubbleContent,
    },
    data() {
        return {
            columns: [
                { text: this.$t('generic.name') },
                { text: this.$t('generic.classified_f') },
                { text: this.$t('generic.missing') },
                { text: this.$t('generic.total') },
                { text: this.$t('generic.lastEntry') },
            ],
        };
    },
    computed: {
        classifiers() {
            if (this.group) {
                return this.$store.getters['users/withId'](this.group.users)
            }
            return []
        },
        group() {
            return this.$store.getters['exams/groups/getId'](this.id)
        },
    },
    mounted() {},
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';


.info-classifiers-nav {
    padding: 1rem;
    border-bottom: 2px solid $primary-color;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    p {
        margin: 0;
        font-weight: bold;
    }
}


.info-list-classifiers {
    margin: 0;
    padding-left: 1rem;
    border-top: 1px solid gray;

    &:hover {
        background: rgba($primary-color, 0.1);
        cursor: pointer;

    }

    .info-classifiers {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: repeat(5, 1fr);

    }

}
</style>
