import axios from '@/axiosInstance'
//import theEcho from '@/echoInstance'

const exams = {
    namespaced: true,
    state: () => ({
        all: [],
        examId: null,
        page: 0,
    }),
    getters: {
        id: (state) => (id) => {
            if( state.examId == id ) {
                return state.all
            }
            return state.all.filter((answer) => answer.exam_id === parseInt(id));
        },
    },
    mutations: {
        all(state, result) {
            const raw = result.raw
            const answers = raw.data
            if( result.examId != state.examId ) {
                state.all = []
            }
            state.examId = result.examId
            let index = raw.current_page * raw.per_page - raw.per_page
            for( let answer of answers ) {
                state.all[index++] = answer
            }
        },
        clear(state) {
            state.all = []
        },
    },
    actions: {
        async getAll({ state, commit }, {examId, offset}) {
            const page = Math.ceil(offset / 10)
            if( state.all[offset] != null ) {
                return
            }
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/${examId}/answers`,
                    {params: {
                        page,
                    }},
                );
                commit("all", {raw: results.data.data, examId: parseInt(examId)});
            } catch (error) {
                console.log("ERROR: " + error);
                commit('clear')
            }
        },
    },
};

export default exams;
