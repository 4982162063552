<template>
    <BubbleContent :title="group.name" :buttons="button" @submit="edit">
        <template #form>
            <p v-if="supervisor">
                <strong v-text="`${$tc('models.supervisor')}:`"></strong>
                <span> {{ supervisor.name }}</span>
            </p>
            <h3 v-text="`${$tc('models.classifier', 2)}:`"></h3>
            <div class="group-items">
                <p class="items" v-for="column of columns" :key="column.text">{{ column.text }}</p>
            </div>
            <div v-for="classifier in classifiers" :key="classifier.id" class="list-classifiers">
                <div class="classifiers">
                    <p>
                    <span v-if="classifier.name" v-text="classifier.name"></span>
                    <span v-else v-text="classifier.nr_classificador"></span>
                    </p>
                    <p>
                    <span v-text="classifier.classified"></span> / <span v-text="classifier.classifications_total"></span>
                    </p>
                    <span @click.prevent="removeClassifier(classifier)" v-text="`${$t('action.decisive.remove')}`"></span>
                </div>
            </div>
            <div>
                <p v-text="`${$t('action.shaping.lookForSupervisor')}:`"></p>
                <input type="text" v-model="searchClassifier">
                <div class="usersList">
                    <p v-for="classifier in groupClassifiers" :key="classifier.id"
                        @click.prevent="addClassifier(classifier)">
                        <span v-if="classifier.name">
                            <strong v-text="`${$t('generic.name')}:`"></strong> {{ classifier.name }} |
                        </span>
                        <span v-if="classifier.email">
                            <strong v-text="`${$t('generic.email')}:`"> </strong> {{ classifier.email }} |
                        </span>
                        <span v-if="classifier.nr_classificador">
                            <strong v-text="`${$t('generic.classifierNumber')}:`"></strong> {{ classifier.nr_classificador }} |
                        </span>
                    </p>
                </div>
            </div>
            <br>
        </template>
    </BubbleContent>
</template>
<script>
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';

export default {
    props: {
        id: {
            required: true,
        },
    },
    components: {
        BubbleContent,
    },
    data() {
        return {
            button: [
                { text: `${this.$t('action.shaping.updateGroup')}`, type: 'submit' },
                { text: `${this.$t('action.shaping.removeGroup')}`, type: 'submit', click: this.delete },
            ],
            columns: [
                { text: `${this.$t('generic.name')} / ${this.$t('generic.classifierNumber')}` },
                { text: `${this.$tc('models.classification', 2)}` },
            ],
            searchClassifier: '',
            classifier: null,
            newClassifiers: [],
            removeClassifiers: [],
        };
    },
    methods: {
        edit() {
            this.$store.dispatch('exams/groups/edit', {
                group: this.groupData,
                examId: this.group.exam_id,
                examClass: this.id,
            })
        },
        delete() {
            this.$store.dispatch('exams/groups/delete', { exam: this.group.exam_id, examClass: this.id })
        },
        addClassifier(user) {
            this.$store.dispatch('exams/groups/addClassifier', { examClass: this.id, classifierId: user.id })
        },
        removeClassifier(user) {
            const classifierId = this.group.classifierIds[user.id]
            this.$store.dispatch('exams/groups/remClassifier', { classifierId })
        },
    },
    computed: {
        classifiers() {
            if( this.group == null ) {
                return []
            }
            return this.group.classifiers
        },
        group() {
            return this.$store.getters['exams/groups/getId'](this.id)
        },
        users() {
            return this.$store.state.users.all
        },
        groupClassifiers() {
            if (this.searchClassifier) {
                return this.users.filter((supervisor) => {
                    let search = this.searchClassifier.toString().toLocaleLowerCase()
                    let includesName = false
                    if (supervisor.name) includesName = supervisor.name.toLowerCase().includes(search)
                    let includesNr = false
                    if (supervisor.nr_classificador) includesNr = supervisor.nr_classificador.toLowerCase().includes(search)
                    return includesName || includesNr
                }).slice(0, 5)
            } else {
                return []
            }
        },
        groupData() {
            if (this.supervisor == null || this.group == null) {
                return null
            }
            return {
                name: this.group.name,
                user_id: this.supervisor.id,
                classifierIds: this.classifiers.map(({ id }) => id),
            }
        },
        supervisor() {
            if( this.group == null ) {
                return null
            }
            if( this.group.user_id == null ) {
                return null
            }
            return this.$store.getters['users/getId'](this.group.user_id)
        },
    },
    watch: {
        group: {
            immediate: true,
            handler() {
                if ( this.group == null ) {
                    return
                }
                if( this.group.user_id != null ) {
                    this.$store.dispatch('users/getId', this.group.user_id)
                }
                if( this.group.users == null ) {
                    this.$store.dispatch('exams/groups/getClassifiers', this.id )
                    return
                }
                for (let userId of this.group.users) {
                    this.$store.dispatch('users/getId', userId)
                }
            }
        }
    },
    mounted() {
        this.$store.dispatch("users/getAll");
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.group-items {
    padding: 1rem;
    border-bottom: 2px solid $primary-color;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .items {
        margin: 0;
        font-weight: bold;
    }
}

.list-classifiers {
    margin: 0;
    padding-left: 1rem;
    border-bottom: 1px solid gray;

    &:hover {
        background: rgba($primary-color, 0.1);
    }

    .classifiers {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>
