<template>
    <div>
        <TopContainerNav :title="`${$tc('models.document')}`"/>
        <TopContainerNavBody :actions="actions" />
        <div>
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import TopContainerNav from '@/components/SCOI/TopContainerNav/TopContainerNav.vue';
import TopContainerNavBody from '@/components/SCOI/TopContainerNav/TopContainerNavBody.vue';

export default {
    components: {
        TopContainerNav,
        TopContainerNavBody,
    },
    data() {
        return {

        };
    },
    computed: {
        actions() {
            return [
                { text: `${this.$tc('models.document')}`, route: '/documents', show: true },
                { text: `${this.$t('action.decisive.add')}`, route: { name: 'DocumentCreate' }, show: this.isAdmin },

            ]
        },
        currentUser() {
            return this.$store.state.users.currentUser
        },
        isAdmin() {
            return this.currentUser != null && this.currentUser.role == 1
        },
    },

}
</script>
<style lang="scss" scoped></style>
