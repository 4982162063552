<template>
    <div class="items">
        <ContentCard :title="`${$tc('models.item')}`" :buttons="importButtons" @submit="uploadImportFiles" :loading="importLoading">
        <template #form>
            <InputFile :button="`${$tc('generic.attachment')}`" :label="`${$tc('generic.attachment')}`" v-model="importFiles" />
        </template>
        </ContentCard>

        <ContentCard class="exams-items-items" :title="`${$tc('models.item')}`">
        <div class="exams-items">
            <p class="columns" v-for="column of columns" :key="column.text">{{ column.text }}</p>
        </div>
        <p v-for="item in items" :key="item.id" class="items-list">
        <router-link :to="{ name: 'ExamItemEdit', params: { itemId: item.id } }">
            <div class="items">
                <p>{{ item.name }}</p>
                <p>{{ item.effort }}</p>
                <p>{{ item.overflow }}</p>
                <p>{{ item.supervisor_distribution }}</p>
                <p v-text="item.latex ? 'LaTex' : 'Texto'"></p>
                <button class="btn-removeItems" @click.prevent="showPopup(item.id)" v-text="`${$tc('action.decisive.remove')}`"></button>
            </div>
        </router-link>
        <DeletePopup v-show="popupVisible == item.id" @cancel="closePopup" @execute="deleteObj(item)" />
        </p>
        </ContentCard>

        <ExamCreateItem :id="id"/>

    </div>
</template>

<script>
    import axios from "@/axiosInstance"
    import ContentCard from "@/components/ContentCard.vue";
    import DeletePopup from '@/components/SCOI/DeletePopup/DeletePopup.vue';
    import InputFile from '@/components/SCOI/Inputs/InputFile.vue';
    import ExamCreateItem from "@/components/SCOI/TabExams/ExamItems/ExamCreateItem.vue";

    export default {
        props: {
            id: {
                required: true,
            },
        },
        components: {
            ContentCard,
            DeletePopup,
            ExamCreateItem,
            InputFile,
        },
        data() {
            return {
                columns: [
                { text: `${this.$tc('models.item')}` },
                { text: `${this.$tc('generic.effortIndex')}` },
                { text: `${this.$tc('generic.overlayPercentage')}` },
                { text: `${this.$tc('action.shaping.classifiedBySupervisor')}` },
                { text: `${this.$tc('generic.type')}` },
            ],
                importFiles: [],
                importLoading: false,
                popupVisible: null,
            };
        },
        methods: {
            closePopup() {
                this.popupVisible = null;
            },
            deleteObj(item) {
                this.$store.dispatch('exams/items/delete', item.id)
            },
            showPopup(id) {
                this.popupVisible = id;
            },
            async uploadImportFiles() {
                this.importLoading = true
                try {
                    const res = await axios.get(
                        `${process.env.VUE_APP_API_HOST}/exams/${this.id}/columns`
                    )
                    console.log(res)
                    this.importLoading = false
                } catch (error) {
                    let errorObj = error.response.data
                    let errorMessage = errorObj.message
                    errorObj = { message: errorMessage, error: true, done: true, uid: this.uid }
                    this.$store.commit('loadingMessages/messages', errorObj, { root: true })
                    this.columns = []
                }
            },
        },
        computed: {
            canImportFiles() {
                return this.importFiles.length > 0
            },
            importButtons() {
                return [
                    { text: `${this.$tc('action.decisive.import')}`, type: 'submit', disabled: !this.canImportFiles }
                ]
            },
            items() {
                return this.$store.state.exams.items.all
            },
        },
        mounted() {
            this.$store.dispatch('exams/items/getAll', this.id)
        },

    }
</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    height: 100%;
    padding: $regular-padding;

    > * {
        flex-shrink: 0;
    }
}

.exams-items {
    padding: 1rem;
    border-bottom: 2px solid $primary-color;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    .columns {
        margin-right: 20px;
    }

    p {
        margin: 0;
        font-weight: bold;
    }
}

.items-list {
    margin: 0;
    border-top: 1px solid gray;




    &:hover {
        background: rgba($primary-color, 0.1);

    }

    .items {
        margin: 0;
        // padding-left: 1rem;
        display: grid;
        grid-template-columns: repeat(6, 1fr);

        .btn-removeItems {
            color: $red;
            border: none;
            border: 1px solid $red;
            cursor: pointer;
            padding: 5px;
            margin-left: 5px;
            border-radius: 5px;
            font-size: small;
            margin: auto;

            &:hover{
                background-color: $red;
                color: $white;
            }
        }

        .material-icons {
            margin-top: 15px;
            width: 20px;
            fill: $red;
            margin-left: 50px;


            &:hover {
                margin-top: 12px;
                font-size: larger;
            }
        }



        span {
            margin-top: 15px;
            color: $red;

            &:hover {
                margin-top: 12px;
                font-size: larger;
            }
        }
    }
}
</style>
