<template>
  <p>
    {{label}} 
    <span @click="editing=true" v-show="!editing">
      {{value}}
    </span>
    <span v-show="editing" >
      <input :value = "modelValue"
             @input="$emit('update:modelValue', $event.target.value)"
             @keydown.enter = "editing = false"
             type="text" 
             :placeholder="`${$t('action.shaping.chooseYourName')}`"
             >
    </span>
  </p>
</template>
<script>

export default {
    props: {
        label: {
            default: '',
        },
        value: {
            default: '',
        },
        modelValue: {
            default: '',
        },
    },
    emits: [
        'update:modelValue',
    ],
    data() {
        return {
            editing: false,
        }
    },
    
}
</script>
<style lang="scss" scoped>
    
</style>