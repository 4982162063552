<template>
    <div class="content-students-edit">
        <BubbleContent :title="`${$t('action.decisive.change')}`" :buttons=buttons @submit="$store.dispatch('students/edit', {id, student: {name, school}})">
            <template #form>
                <InputContent :label="`${$t('generic.name')}`" v-model="name" />
                <InputSelectContent :label="`${$tc('models.school')}`" :options="options" v-model="selected"/>
            </template>
        </BubbleContent>
    </div>
</template>
<script>
import BubbleContent from '../BubbleContent/BubbleContent.vue';
import InputContent from '../Inputs/InputContent.vue';
import InputSelectContent from '@/components/input/SelectComponent.vue';


export default {
    components: {
        BubbleContent,
        InputContent,
        InputSelectContent,
    },
    props: {
        id: {
            required: true
        }
    },
    data() {
        return {
            name: "",
            buttons: [
                { text: `${this.$t('action.decisive.edit')}`, type: 'submit' },
            ],
            options: [
                {text: `${this.$tc('models.school')}`, value: '0'},
            ],
        };
    },
    computed: {
        newStudentData() {
            return {
                name: this.name,
                school: this.school,
            }
        },
        student(){
            return this.$store.getters['students/getId'](this.id)
        },
    },
    watch: {
        student: {
            immediate: true,
            handler(){
                if(this.school != null){
                    this.name = this.school.name
                    this.school = this.school.school
                }
            },
        }
    },
    mounted() {
        if(this.school == null) {
            this.$store.dispatch('schools/getId', this.id)
        }
    },
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.content-schools-edit {
    margin: 20px;
    color: $black;

    :deep .field {
        margin-bottom: 1.5em;

        input {
            border-radius: 0;
            background-color: $white;
            border: none;
            border-bottom: 4px solid $secondary-color;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            overflow: visible;
            font-family: sans-serif;
            font-size: 100%;
            line-height: 1.15;
            margin: 0;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }
        }

        #role {
            color: $black;
            margin: 0;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            border: none;
            font-size: 100%;
            margin: 0;
            border-bottom: 4px solid $secondary-color;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }


        }
    }
}
</style>
