const students = {
    namespaced: true,
    state: () => ({
      all: [],
      student: null,
    }),
    getters: {
      getId: (state) => (id) => {
        return state.all.find((student) => student.id === parseInt(id));
      },
    },
    mutations: {
      add(state, newStudent) {
        state.all.push(newStudent);
      },
      all(state, students) {
        state.all = students;
      },
      delete(state, student) {
        const index = state.all.findIndex(({ id }) => student.id === id);
        state.all.splice(index, 1);
      },
      student(state, student) {
        const index = state.all.findIndex(({ id }) => student.id === id);
        if (index < 0) {
          state.all.push(student);
        } else {
          state.all.splice(index, 1, student);
        }
      },
    },
    actions: {
      async create({ rootState, commit }, newStudent) {
        try {
          const results = await rootState.axios.post(
            `${process.env.VUE_APP_API_HOST}/students`,
            newStudent
          );
  
          commit("add", results.data);
        } catch (error) {
          console.log("ERROR: " + error);
        }
      },
      async delete({ rootState, commit }, id) {
        try {
          const results = await rootState.axios.delete(
            `${process.env.VUE_APP_API_HOST}/students/${id}`
          );
          commit("delete", results.data.data);
        } catch (error) {
          console.log("ERROR: " + error);
        }
      },
      async edit({ rootState, commit }, { id, student }) {
        try {
          const results = await rootState.axios.put(
            `${process.env.VUE_APP_API_HOST}/students/${id}`,
            student
          );
          commit("student", results.data.data);
        } catch (error) {
          console.log("ERROR: " + error);
        }
      },
      async getAll({ rootState, commit }) {
        try {
          const results = await rootState.axios.get(
            `${process.env.VUE_APP_API_HOST}/students`
          );
          commit("all", results.data.data);
        } catch (error) {
          console.log("ERROR: " + error);
        }
      },
      async getId({ getters, dispatch }, id) {
        if (getters.getId(id) == null) {
          dispatch("fetchId", id);
        }
      },
    },
  };
  
  export default students;
  