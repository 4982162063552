<template>
    <div class="answers">
        <div class="filter">
            <ul>
                <li v-for="item in items"
                    :key="item.id"
                    v-text="item.name"
                    :class="{selected: selected.includes(item.id)}"
                    @click.prevent="toggleItem(item)">
                </li>
            </ul>
        </div>

        <h2 v-if="exam">
            <span v-if="submission">
                {{ $t('models.student') }}: <span v-text="submission[exam.student_col]"></span>
            </span>
            -
            <input v-model="thePage" type="number" class="pageInput" :style="{'--chars': thePageChars}">/{{exam.submissions}}
        </h2>

        <div class="exam-answers-content" v-for="item in filteredItems" :key="item.id">
            <BubbleContent :title="`${item.name} ${page}/${submissions.length}`" :buttons="button" :loadingInfo="loadingInfo">

                <div class="content-grid" :class="{ 'content-grid_popup': isVisible }">
                    <div class="content-info">

                        <h2 v-if="itemClassification">{{vTextAnswer}}# {{ itemClassification.id }}</h2>

                        <div class="answers-content">
                            <div class="attached-customFile" v-if="itemClassification && itemClassification.custom_file">
                                <a target="_blank" :href="itemClassification.custom_file">
                                    <IconComponent icon="clip" class="material-icons" />
                                    <span v-text="`${$t('action.shaping.toClassifyAnswerSeeFileAttach')}`"></span>
                                </a>
                            </div>
                            <AnswerRender :submission="answers[item.id]" :item="item" v-if="answers[item.id]"/>
                        </div>

                        <div>
                            <h3 v-text="`${$tc('models.classification')}`"></h3>
                            <button v-if="!isVisible" class="answers-credits" @click="showPopup" v-text="`${$t('action.shaping.seeCriteria')}`"></button>
                            <button v-if="isVisible" class="answers-credits" @click="closePopup" v-text="`${$t('action.shaping.hideCriteria')}`"></button>
                            <SelectItemCodes :codes="itemCodes" :class="{ active: restrictionsCombined }"
                                :disabled="restrictionsBlocked" :itemId="item.id" :selectedCodes="codesSelected"
                                v-model="selectedCode" />
                            <div v-if="messageIsVisible">
                                <InputContent :label="`${$t('action.shaping.writeMessage')}`" v-model="messageReview" />
                            </div>
                            <div>
                                <strong v-for="message in messages" :key="message">{{ message }}</strong>
                            </div>
                        </div>
                    </div>
                    <transition name="fade">
                        <ClassifierExamCriteriaPopup class="popup" v-if="isVisible" @close="closePopup" :id="id"
                            :itemId="itemId" />
                    </transition>
                </div>
            </BubbleContent>

        </div>
    </div>
</template>

<script>
import AnswerRender from '@/components/AnswerRender.vue'
import SelectItemCodes from '@/components/SCOI/ClassifiersPlatform/ClassifiersExams/ClassifiersExam/ClassifierExamAnswersContent/SelectItemCodes.vue'
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';
import ClassifierExamCriteriaPopup from '@/components/SCOI/ClassifiersPlatform/ClassifiersExams/ClassifiersExam/ClassifierExamAnswersContent/ClassifierExamCriteriaPopup.vue'
import InputContent from '@/components/SCOI/Inputs/InputContent.vue';

import renderMathInElement from 'katex/dist/contrib/auto-render';
import 'katex/dist/katex.min.css';

export default {
    props: {
        id: { required: true },
        state: { required: true },
        disabled: { default: null, type: String }
    },
    components: {
        AnswerRender,
        BubbleContent,
        SelectItemCodes,
        ClassifierExamCriteriaPopup,
        InputContent,
    },
    data() {
        return {
            vTextAnswer: `${this.$tc('model.answer')}:`,
            vTextWords: `${this.$tc('generic.word')}:`,
            page: 1,
            selectedCode: [],
            selectedState: 3, // Done,
            isVisible: false,
            index: 0,
            item: null,
            messageIsVisible: false,
            messageReview: '',
            selected: [],
            active: [],
            delimiters: [
                {left: '$$', right: '$$', display: true},
                {left: '$', right: '$', display: false},
                {left: '(formula: ', right: ')', display: false},
                {left: "\\(", right: "\\)", display: false},
                {left: "\\begin{equation}", right: "\\end{equation}", display: true},
                {left: "\\begin{align}", right: "\\end{align}", display: true},
                {left: "\\begin{alignat}", right: "\\end{alignat}", display: true},
                {left: "\\begin{gather}", right: "\\end{gather}", display: true},
                {left: "\\begin{CD}", right: "\\end{CD}", display: true},
                {left: "\\[", right: "\\]", display: true}
            ],
            thePage: 1,
            thePageTimeout: null,
            thePageChars: 1,
        }
    },
    methods: {
        closePopup() {
            this.isVisible = false
        },
        selectState(state) {
            if (this.selectedState == state) {
                this.selectedState = 3
                return
            }
            this.selectedState = state
        },
        showPopup() {
            this.isVisible = true
        },
        showMessage() {
            this.messageIsVisible = !this.messageIsVisible
        },
        submit() {
            if (this.canSubmit) {
                this.$store.dispatch('itemClassification/classify', {
                    classification: this.itemClassification,
                    code: this.selectedCode,
                    state: this.selectedState,
                })
                this.$store.dispatch('storeMessage', this.messageReview) //!added this to store message
            }
        },
        toggleItem(item) {
            if( this.selected.includes(item.id) ) {
                this.selected = this.selected.filter((id) => id != item.id)
                return
            }
            this.selected.push(item.id)
        },
    },
    computed: {
        answers() {
            if( !this.submission ) {
                return {}
            }
            let answers = {}
            for(let item of this.items) {
                let answer = []
                for( let col of item.columns ) {
                    answer.push(this.submission[col])
                }
                answers[item.id] = answer
            }
            return answers
        },
        button() {
            return [
                { text: this.$tc('action.decisive.before'), type: 'submit', click: () => { this.page -= 1 }, disabled: !this.canSubmit },
                { text: this.$tc('action.decisive.next'), type: 'submit', click: () => { this.page += 1 }, disabled: !this.canSubmit },
            ]
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        loadingInfo() {
            return {
                uid: this.page,
                done: true,
                timeout: 0,
            }
        },
        messages() {
            let messages = this.$store.state.itemClassification.messages
            messages.push(this.messageReview)
            let phrase = messages.slice(-1)[0]
            return phrase
        },
        filteredItems() {
            if( this.selected.length == 0 ) {
                return this.items
            }
            return this.items.filter( ({id}) => this.selected.includes(id) )
        },
        hideWordCount() {
            return this.item && this.item.latex
        },
        nrCodeParams() {
            if (this.itemCodes) {
                let names = this.itemCodes.map(({ name }) => name)
                return names.filter((name, index) => names.indexOf(name) === index).length
            }
            return -1
        },
        submission() {
            return this.submissions[this.page - 1]
        },
        submissions() {
            return this.$store.getters['exams/answers/id'](this.id)
        },
        canSubmit() {
            if (this.messageIsVisible && this.messageReview.length < 20) {
                return false
            }
            return this.selectedCode.length == this.nrCodeParams
        },
        codesSelected() {
            if( this.itemClassification == null ) {
                return null
            }
            return this.itemClassification.item_code_ids
        },
        classificationsCount() {
            if (this.item) {
                return this.item.classifications_count[this.state]
            }
            return 0
        },
        itemCodes() {
            return []
            // return this.$store.state.itemCodes.all
        },
        items() {
            return this.$store.getters['exams/items/byExamId'](this.id)
        },
        getItemClassifications() {
            return null
            // return this.$store.getters['itemClassification/getItem'](this.itemId, this.state)
        },
        selectedParams() {
            let params = [];
            for (var code in this.selected) {
                params.push(this.selected[code].name);
            }
            return params;
        },
        restricted() {
            return this.itemCodes.filter((code) => {
                return !(this.selectedParams.includes(code.name) || this.active.includes(code));
            })
        },
        restrictions() {
            return null
            // return this.$store.getters['exams/items/restrictions'](this.itemId)
        },
        restrictionsBlocked() {
            return []
            // return this.restrictions.map((restriction) => restriction.rules.blocked)
        },
        restrictionsCombined() {
            return []
            // return this.restrictions.map((restriction) => restriction.rules.combination)
        },
    },
    watch: {
        page: {
            immediate: true,
            // let params = {
            //     exam: this.id,
            //     examItem: this.itemId,
            //     page: this.page + 1,
            //     state: this.state,
            // }
            // this.$store.dispatch('itemClassification/getOne', params)
            handler() {
                this.$store.dispatch('exams/answers/getAll', {
                    examId: this.id,
                    offset: this.page,
                })
                if( this.page != this.thePage ) {
                    this.thePage = this.page
                }
            }
        },
        thePage() {
            this.thePageChars = this.thePage.toString().length
            clearTimeout(this.thePageTimeout)
            this.thePageTimeout = setTimeout( () => {
                if( this.thePage > this.exam.submissions 
                    || this.thePage < 1
                    || this.thePage == ""
                ) {
                    this.thePage = this.page
                    return
                }
                this.page = this.thePage
            }, 500)
        },
        itemClassification() {
            this.selectedCode = []
            let params = {
                exam: this.id,
                examItem: this.itemId,
                page: this.page + 1,
                state: this.state,
            }
            this.$store.dispatch('itemClassification/getOne', params)
        },
        getItemClassifications() {
            if (this.getItemClassifications.length == 0) {
                this.$router.replace({ name: "Exam" })
            }
        },
        submissions: {
            immediate: true,
            handler() {
                if( this.submissions.length < 1 ) {
                    return
                }
                this.$nextTick().then( () => {
                    let condition = false
                    if( condition ) {
                        renderMathInElement(this.$refs.answer, {
                            delimiters: this.delimiters,
                            throwOnError: false,
                        })
                    }
                })
            }
        },
        items: {
            immediate: true,
            handler() {
                if( this.items.length == 0 ) {
                    return
                }
                for( let item of this.items ) {
                    this.$store.dispatch('itemCodes/getAll', {item: item.id})
                }
            },
        },
    },
    mounted() {
        // this.$store.dispatch('exams/items/getRestrictions', this.itemId)
        // this.$store.dispatch('itemClassification/getOne', { exam: this.id, examItem: this.itemId, state: this.state })
    },

}
</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.item-title {
    margin-top: 0;
}

.pageInput {
    width: calc(var(--chars) * .65em);
    min-width: 2rem;
    text-align: right;
    margin:0;
    border: 1px solid;
    display: inline-block;

    appearance: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;

    li {
        &.selected {
            font-weight: bold;
        }
    }
}

.answers {
    padding: $regular-padding;
    max-height: 100%;
    overflow: auto;
}

.exam-answers-content {
    margin-bottom: $regular-padding;

    .content-grid {
        padding: 20px 0;
        display: grid;
        grid-template-columns: 0fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            'popup info'
        ;
        gap: 0px;
        transition: .3s ease-out;
        transition-property: gap, grid-template-columns;
        // grid-template-areas:
        //     'info info'
        // ;

        &_popup {
            // grid-template-areas:
            //     'popup info'
            // ; 
            gap: 20px;
            grid-template-columns: 1fr 1fr;
        }

        .popup {
            grid-area: popup;
        }

        .content-info {
            grid-area: info;
            display: flex;
            flex-direction: column;
        }

        .answers-iconItem-content {
            display: flex;
            align-items: baseline;

            h2 {
                margin: 0;
            }
        }

        .material-icons {
            width: 15px;
            fill: $primary-color;
            margin-right: 10px;
        }

        .buttons-content {
            display: flex;
            justify-content: space-between;
            padding: 0 20%;
            border-top: 1px solid $primary-color;
            border-bottom: 1px solid $primary-color;

            .buttons-beforeNext {
                background: rgba($black, 0);
                border: none;
                display: inline-block;
                text-transform: uppercase;
                margin: 5px 0;
                cursor: pointer;
                transition-duration: 0.7s;
                position: relative;
                width: 100%;

                &:before {
                    content: '';
                    background-image: linear-gradient(90deg, rgba($primary-color, 0), rgba($primary-color, 1), rgba($primary-color, 0));
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 0;
                    transition: opacity .2s ease;
                }

                &:hover {
                    transition: background-color .7s;

                    &::before {
                        opacity: .25;
                    }
                }
            }
        }

        .answers-content {
            border-bottom: 1px solid rgba($black, .5);
        }

        .attached-customFile {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            color: $primary-color;
        }

        .answer {
            // display: flex;
            // justify-content: center;
            // align-items: center;
            margin: auto;
            width: fit-content;
            max-width: 100%;
            &-part {
                border-bottom: 1px solid rgba($black, 1);
                padding: 30px;
                width: 100%;
            }
        }

        .answers-credits {
            background-color: $primary-color;
            cursor: pointer;
            color: $white;
            padding: 0.5em;
            border: 1px solid $primary-color;
        }


        // .item-codes-content {


        .buttons-item-codes {
            background-color: $white;
            cursor: pointer;
            color: $black;
            padding: 0.5em;
            border: 1px solid $primary-color;
            // width: 24%;
            margin-top: 20px;

            p {
                margin: 0;
                font-weight: 700;

            }

        }

        .content-conditions {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            .buttons-conditions {
                border: 1px solid $primary-color;
                background: $white;
                color: $text-color;
                font-weight: 700;
                cursor: pointer;
                padding: 10px;


                &.active {
                    background-color: $primary-color;
                    color: $white;
                }
            }
        }
    }
}
</style>

