<template>
    <div class="container">
        <div class="header">
            <h1>{{ titleText }}
                <small v-if="exam && exam.status" v-text="exam.status"></small>
                <template v-if="pingedAt">
                    <small v-text="pingedAt"></small>
                    <small>-</small>
                    <small @click.prevent="reset" v-text="`${$t('action.shaping.reset')}`"></small>
                </template>
            </h1>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: ['title', 'status', 'exam'],
    
    computed: {
        titleText() {
            if( this.exam ) {
                return this.exam.name
            }
            return this.title
        },
        pingedAt() {
            if( this.exam && this.exam.task_pinged_at ) {
                console.log(this.exam.task_pinged_at)
                console.log(this.exam)
                return moment(this.exam.task_pinged_at).format("YYYY-MM-DD HH:mm:ss")
            }
            return null
        },
    },
    methods: {
        reset() {
            this.$store.dispatch('exams/reset', this.exam.id)
        }
    },
}

</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 100%;

    small {
        margin: 0 .5em;
        font-size: .7em;
    }

    .header {
        padding: 10px 18px;
        background: $primary-color;
        color: $white;

        h1 {
            margin: 0;
            font-size: 1.35em;
            text-transform: uppercase;
        }
    }
}
</style>