<template>
    <a href="#"
        @click.prevent="selectButton"
        :class="{ selected }"
        :data-id="id"
    >

        <span v-if="attribution && !editMode" v-text="attribution.classifications_number ">
        </span>

        <input
            @keypress.enter="setClassificationsNumber"
            @focusout="this.editMode = false"
            type="number"
            ref="input"
            v-model="attributed"
            v-if="editMode">
    </a>
</template>

<script>
export default {
    props: {
        id: {required: true},
        classifierId: {required: true},
        itemId: {required: true},
    },
    data() {
        return {
            attributed: 0,
            editMode: false,
            // select: false,
        }
    },
    methods: {
        edit() {
            this.editMode = true
            this.$nextTick(() => {
                const input = this.$refs.input
                if( input != null ) {
                    input.focus()
                }
            })
        },
        setClassificationsNumber() {
            this.$store.dispatch('exams/distribution/setAttribution', {
                exam: this.attribution.exam_id,
                classifier: this.classifierId,
                examItem: this.itemId,
                amount: this.attributed,
            })
            this.editMode = false
        },
        selectButton(e) {
            if( this.attribution ) {
                this.attribution.selected = !this.attribution.selected
            } else {
                this.$store.commit('exams/distribution/selectNew', {
                    exam_id: this.item.exam_id,
                    classifier_id: this.classifierId,
                    exam_item_id: this.itemId,
                })
            }
            let params = {value: !this.selected, shift: false}
            if (e && e.shiftKey ) {
                params.shift = true
                this.$emit('select', params)
                return
            }
            this.$emit('select', params)
        },
    },
    computed: {
        attribution() {
            let attribution = this.$store.getters['exams/distribution/attribution'](this.classifierId, this.itemId)
            if( attribution == null ) {
                attribution = this.newAttribution
            }
            return attribution
        },
        newAttribution() {
            return this.$store.getters['exams/distribution/newAttribution'](this.classifierId, this.itemId)
        },
        item() {
            return this.$store.getters['exams/items/getId'](this.itemId)
        },
        selected() {
            if( this.attribution == null && this.newAttribution == null ) {
                return false
            }
            if( this.newAttribution != null ) {
                return this.newAttribution.selected
            }
            return this.attribution.selected
        },
    },
    watch: {
        attribution: {
            immediate: true,
            handler() {
                if( this.attribution == null ) {
                    return
                }
                this.attributed = this.attribution.classifications_number
            },
        }
    },
    mounted() {
    },
}

</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

a, input {
    width: 100%;
    height: 3.5em;
    border: 2px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 4px;
}
input {
    border-color: black;
    &:focus {
        outline: $primary-color;
    }
}
input {
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 0;
    border: 8px solid $primary-color;
    outline: none;
}

a {
    border-color: $primary-color;
    font-weight: bold;
    color: rgba(black, 0);
    position: relative;

    &.selected {
        color: rgba(black, 1);
        background-color: rgba($primary-color, .5);

        &:hover {
            svg {
                opacity: 1;
            }
        }
    }
}

</style>
