<template>
    <div>
        <div ref="answer">
            <div v-for="answer, index in answers" :key="index" class="answer-part">
                <div class="answer" v-html="answer"></div>
            </div>
        </div>
        <strong v-if="!hideWordCount" class="answer-number-words">{{vTextWords}}
            {{ words_count }}</strong>
    </div>
</template>

<script>
    import renderMathInElement from 'katex/dist/contrib/auto-render';
    import 'katex/dist/katex.min.css';

    export default {
        props: {
            submission: {required: true },
            item: { required: true },
        },
        data() {
            return {
                vTextItem: `${this.$tc('models.item')}:`,
                vTextAnswer: `${this.$tc('models.answer_nr')}:`,
                vTextWords: `${this.$tc('generic.word')}:`,
                page: 0,
                selectedCode: [],
                selectedState: 3, // Done,
                isVisible: false,
                messageIsVisible: false,
                messageReview: '',
                selected: [],
                active: [],
                delimiters: [
                    {left: '$$', right: '$$', display: true},
                    {left: '$', right: '$', display: false},
                    {left: '(formula: ', right: ')', display: false},
                    {left: "\\(", right: "\\)", display: false},
                    {left: "\\begin{equation}", right: "\\end{equation}", display: true},
                    {left: "\\begin{align}", right: "\\end{align}", display: true},
                    {left: "\\begin{alignat}", right: "\\end{alignat}", display: true},
                    {left: "\\begin{gather}", right: "\\end{gather}", display: true},
                    {left: "\\begin{CD}", right: "\\end{CD}", display: true},
                    {left: "\\[", right: "\\]", display: true}
                ]
            }
        },
        methods: {
            closePopup() {
                this.isVisible = false
            },
            selectState(state) {
                if (this.selectedState == state) {
                    this.selectedState = 3
                    return
                }
                this.selectedState = state
            },
            showPopup() {
                this.isVisible = true
            },
            showMessage() {
                this.messageIsVisible = !this.messageIsVisible
            },
            submit() {
                if (this.canSubmit) {
                    this.$store.dispatch('itemClassification/classify', {
                        classification: this.itemClassification,
                        code: this.selectedCode,
                        state: this.selectedState,
                    })
                    this.$store.dispatch('storeMessage', this.messageReview) //!added this to store message
                }
            },
            isUrl(str) {
                if ( (!str.includes(".")) || /\s/.test(str)) {
                    return false;
                }
                let formats = [
                    ".tif",
                    ".gif",
                    ".jpeg",
                    ".jpg",
                    ".png",
                    ".pdf",
                ];
                let includes = false;
                for (var format in formats) {
                    if (str.includes(formats[format])) {
                        includes = true;
                        break;
                    }
                }
                if (!includes) {
                    return false;
                }

                let regex = new RegExp("((http|https)://)(www.)?"
                    + "[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]"
                    + "{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)")
                if (!regex.test(str)) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        computed: {
            answers() {
                let answers = []
                if (!this.item.latex) {
                    let answer = ''
                    for (let ans of this.submission) {
                        if (this.isUrl(ans)) {
                            if( ans.endsWith("pdf") ) {
                                answer = `<object data='${ans}' type='application/pdf'></object>`
                            } else {
                                answer = "<p><img src='" + ans + "'></p>"
                            }
                        } else {
                            answer = ans.replace(/\\taoBr/g, '\\\\');
                        }
                        answers.push(answer);
                    }
                    return answers
                }

                if (this.submission) {
                    let answer = ''
                    for (let ans of this.submission) {
                        if (this.isUrl(ans)) {
                            answer = "<p><img src='" + ans + "'></p>"
                        } else {
                            answer = ans.replace(/\\taoBr/g, '\\\\');
                        }
                    }
                    answers.push(answer);
                }
                return answers
            },
            button() {
                return [
                    { text: this.$tc('action.decisive.classify'), type: 'submit', click: this.submit, disabled: !this.canSubmit },
                ]
            },
            messages() {
                let messages = this.$store.state.itemClassification.messages
                messages.push(this.messageReview)
                let phrase = messages.slice(-1)[0]
                return phrase
            },
            hideWordCount() {
                return this.item && this.item.latex
            },
            nrCodeParams() {
                if (this.itemCodes) {
                    let names = this.itemCodes.map(({ name }) => name)
                    return names.filter((name, index) => names.indexOf(name) === index).length
                }
                return -1
            },
            canSubmit() {
                if (this.messageIsVisible && this.messageReview.length < 20) {
                    return false
                }
                return this.selectedCode.length == this.nrCodeParams
            },
            codesSelected() {
                return this.itemClassification.item_code_ids
            },
            classificationsCount() {
                if (this.item) {
                    return this.item.classifications_count[this.state]
                }
                return 0
            },
            itemCodes() {
                return this.$store.state.itemCodes.all
            },
            itemClassification() {
                if (this.page > 0) {
                    return this.getItemClassifications[this.page - 1]
                }
                return null
            },
            getItemClassifications() {
                return this.$store.getters['itemClassification/getItem'](this.itemId, this.state)
            },
            selectedParams() {
                let params = [];
                for (var code in this.selected) {
                    params.push(this.selected[code].name);
                }
                return params;
            },
            restricted() {
                return this.itemCodes.filter((code) => {
                    return !(this.selectedParams.includes(code.name) || this.active.includes(code));
                })
            },
            restrictions() {
                return this.$store.getters['exams/items/restrictions'](this.itemId)
            },
            restrictionsBlocked() {
                return this.restrictions.map((restriction) => restriction.rules.blocked)
            },
            restrictionsCombined() {
                return this.restrictions.map((restriction) => restriction.rules.combination)
            },
        },
        watch: {
            page() {
                let params = {
                    exam: this.id,
                    examItem: this.itemId,
                    page: this.page + 1,
                    state: this.state,
                }
                this.$store.dispatch('itemClassification/getOne', params)
            },
            itemClassification() {
                this.selectedCode = []
                let params = {
                    exam: this.id,
                    examItem: this.itemId,
                    page: this.page + 1,
                    state: this.state,
                }
                this.$store.dispatch('itemClassification/getOne', params)
            },
            getItemClassifications() {
                if (this.getItemClassifications.length == 0) {
                    this.$router.replace({ name: "Exam" })
                }
            },
            answers: {
                immediate: true,
                handler() {
                    if( this.answers.length < 1 ) {
                        return
                    }
                    this.$nextTick().then( () => {
                        renderMathInElement(this.$refs.answer, {
                            delimiters: this.delimiters,
                            throwOnError: false,
                        })
                    })
                }
            }
        },
        mounted() {
            this.$store.dispatch('exams/items/getRestrictions', this.itemId)
            this.$store.dispatch('itemCodes/getAll', { item: this.itemId })
            this.$store.dispatch('itemClassification/getOne', { exam: this.id, examItem: this.itemId, state: this.state })
            if (this.page == 0) {
                this.page += 1
            }
        },

    }
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';


.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.item-title {
    margin-top: 0;
}


        .exam-answers-content {
            padding: 20px;

            .content-grid {
                padding: 20px 0;
                display: grid;
                grid-template-columns: 0fr 1fr;
                grid-template-rows: auto;
                grid-template-areas:
                    'popup info'
                ;
            gap: 0px;
            transition: .3s ease-out;
            transition-property: gap, grid-template-columns;
            // grid-template-areas:
                //     'info info'
            // ;

            &_popup {
                // grid-template-areas:
                    //     'popup info'
                // ; 
            gap: 20px;
            grid-template-columns: 1fr 1fr;
            }

            .popup {
                grid-area: popup;
            }

            .content-info {
                grid-area: info;
                display: flex;
                flex-direction: column;
            }

            .material-icons {
                width: 15px;
                fill: $primary-color;
                margin-right: 10px;
            }

            .buttons-content {
                display: flex;
                justify-content: space-between;
                padding: 0 20%;
                border-top: 1px solid $primary-color;
                border-bottom: 1px solid $primary-color;

                .buttons-beforeNext {
                    background: rgba($black, 0);
                    border: none;
                    display: inline-block;
                    text-transform: uppercase;
                    margin: 5px 0;
                    cursor: pointer;
                    transition-duration: 0.7s;
                    position: relative;
                    width: 100%;

                    &:before {
                        content: '';
                        background-image: linear-gradient(90deg, rgba($primary-color, 0), rgba($primary-color, 1), rgba($primary-color, 0));
                        background-repeat: no-repeat;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        opacity: 0;
                        transition: opacity .2s ease;
                    }

                    &:hover {
                        transition: background-color .7s;

                        &::before {
                            opacity: .25;
                        }
                    }
                }
            }

            .answers-content {
                border-bottom: 1px solid rgba($black, .5);
            }

            .attached-customFile {
                margin-top: 20px;
                display: flex;
                justify-content: center;
                color: $primary-color;
            }

            .answer {
                margin: auto;
                width: fit-content;
                max-width: 100%;

                &-part {
                    border-bottom: 1px solid rgba($black, 1);
                    padding: 30px;
                    width: 100%;

                    ::v-deep(object) {
                        height: 800px;
                        max-height: 52.5vh;
                        width: 75vw;
                    }
                }
            }

            .answers-credits {
                background-color: $primary-color;
                cursor: pointer;
                color: $white;
                padding: 0.5em;
                border: 1px solid $primary-color;
            }


            // .item-codes-content {


                .buttons-item-codes {
                    background-color: $white;
                    cursor: pointer;
                    color: $black;
                    padding: 0.5em;
                    border: 1px solid $primary-color;
                    // width: 24%;
                margin-top: 20px;

                p {
                    margin: 0;
                    font-weight: 700;

                }

                // &.active {
                    //     background-color: $primary-color;
                    //     color: $white;
                    // }
                }

                // }

        .content-conditions {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            .buttons-conditions {
                border: 1px solid $primary-color;
                background: $white;
                color: $text-color;
                font-weight: 700;
                cursor: pointer;
                padding: 10px;


                &.active {
                    background-color: $primary-color;
                    color: $white;
                }
            }
        }
            }
        }
</style>
