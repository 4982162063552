import SettingsPage from '../views/SettingsPage.vue'
import NomenclatureList from '../components/SCOI/TabSettings/NomenclatureList.vue'
import BackUp from '../components/SCOI/TabSettings/BackUp.vue'

const settings = [
    {
        path: '/definicoes',
        component: SettingsPage,
        children: [
            {
                path:'nomenclatura',
                name: 'NomenclatureList',
                component: NomenclatureList,
            },
            {
                path:'copia-de-seguranca',
                name: 'BackUp',
                component: BackUp,
            },
        ]
    }
]

export default settings;
