const loadingMessages = {
  namespaced: true,
  state: {
    messages: [
      { uid: null,
        message: " A Processar",
        error: null,
        done: true
      }
    ],
  },
  getters: {
    messages: (state) => (uid) => {
      let message = state.messages.find(message => message.uid == uid)
      return message
    },
  },
  mutations: {
    clearMessage(state, uid) {
      state.messages = state.messages.filter( message => message.uid != uid )
    },
    messages(state, message) {
      state.messages.push(message);
    },
  },
  actions: {
    async messages({ commit }, message) {
      setTimeout(() => commit("messages", message), 3000);
    },
  },
};

export default loadingMessages;
