<template>
    <div class="loading" :class="{error, success: warning}">
        <span v-if="error" v-text="errorMessage"></span>
        <IconComponent v-if="error" icon="crossV2" class="error-icon" />
        <span v-if="warning && !error" v-text="warningMessage"></span>
        <IconComponent v-if="warning && !error" icon="correct" class="success-icon" />
        <div class="loader" v-text="`${$t('action.shaping.processing')}`"></div>
    </div>
</template>

<script>
    import IconComponent from '@/components/IconComponent.vue';
    export default {
        props: {
            error: {
                default: false,
            },
            errorMessage: {
            },
            warning: {
                default: false,
            },
            warningMessage: {
            },
        },
        components: {
            IconComponent
        }
    }
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

@keyframes loading {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: opacity 0.75s, visibility 0.75s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    font-size: larger;
    font-weight: bold;

    &.success {
        background: rgba($green, 0.4);
        color: $green;
    }

    &.error {
        background: rgba($red, 0.4);
        color: $red;
    }

}

.loader {
    background: rgba($primary-color, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.75s, visibility 0.75s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    border-radius: 0 0 10px 10px;
    color: $primary-color;
    font-size: larger;
    font-weight: bold;
}



.loader--hidden {
    opacity: 0;
    visibility: hidden;
}

.loader::after {
    content: "";
    width: 50px;
    height: 50px;
    border: 15px solid $white;
    border-top-color: $primary-color;
    border-radius: 50%;
    animation: loading 0.75s linear infinite;
}



.error {
}

.success--hidden {
    opacity: 0;
    visibility: hidden;
}

.error-icon {
    width: 80px;
    fill: $red;
}

.success--hidden {
    opacity: 0;
    visibility: hidden;
}

.success-icon {
    width: 90px;
    fill: $green;
}

</style>
