import axios from "./axiosInstance"


const api = {
    exams: {
        classifications: {
            updateAll(examId) {
                return axios.post(`${process.env.VUE_APP_API_HOST}/exams/${examId}/updateClassifications`)
            },
        },
        iaveTeams: {
            index(id) {
                return axios.get(`${process.env.VUE_APP_API_HOST}/exams/${id}/teams`)
            },
            create(id, user_id) {
                return axios.post(`${process.env.VUE_APP_API_HOST}/exams/${id}/teams`, {
                    user_id
                })
            },
            destroy(id, user_id) {
                return axios.delete(`${process.env.VUE_APP_API_HOST}/exams/${id}/teams/${user_id}`)
            },
        },
        tables() {
            return axios.get(`${process.env.VUE_APP_API_HOST}/exams/tables`)
        },
    },
}

export default api
