<template>
    <div class="content-users-edit" >
        <BubbleContent :title="`${$t('generic.accessUserData')}`">
            <template #form>
                <InputContent :label="`${$t('generic.name')}`" v-model="name" />
                <InputContent :label="`${$t('generic.idNumber')}`" v-model="nr_classificador" />
                <InputContent :label="`${$tc('models.school')}`" />
            </template>
        </BubbleContent>
        <br>
        <BubbleContent :title="`${$t('generic.accessData')}`" :buttons=buttons>
            <template #form>
                <InputContent :label="`${$t('generic.email')}`" type="email" v-model="email" />
                <strong v-text="`${$t('generic.passwordInfo')}`"></strong>
                <InputContent :label="`${$t('generic.password')}`" type="password" v-model="password" />
                <InputContent :label="`${$t('action.shaping.repeatPass')}`" type="password" v-model="password" />
            </template>
        </BubbleContent>
    </div>
</template>
<script>
import BubbleContent from '../BubbleContent/BubbleContent.vue';
import InputContent from '../Inputs/InputContent.vue';

export default {
    components: {
        BubbleContent,
        InputContent,
    },
    props: {
        id: {
            default: null
        }
    },
    data() {
        return {
            name: "",
            nr_classificador: "",
            school_id: "",
            email: "",
            password: "",
            buttons: [
                { text: `${this.$t('action.decisive.change')}`, type: 'submit' },
            ],
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.users.currentUser
        },
    },
    watch: {
        currentUser: {
            immediate: true,
            handler() {
                if (this.currentUser != null) {
                    this.name = this.currentUser.name
                    this.email = this.currentUser.email
                    this.school_id = this.currentUser.school_id
                    this.nr_classificador = this.currentUser.nr_classificador
                }
            },
        }
    },
    mounted() {},
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.content-users-edit {
    margin: 20px;
    color: $black;

    :deep .field {
        margin-bottom: 1.5em;

        input {
            border-radius: 0;
            background-color: $white;
            border: none;
            border-bottom: 4px solid $secondary-color;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            overflow: visible;
            font-family: sans-serif;
            font-size: 100%;
            line-height: 1.15;
            margin: 0;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }
        }

        #role {
            color: $black;
            margin: 0;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            border: none;
            font-size: 100%;
            margin: 0;
            border-bottom: 4px solid $secondary-color;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }


        }
    }
}
</style>
